import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import CalendarDates from "../../components/CalendarDates";
import { Modal } from "antd";

const Calendar = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = (e) => {
    console.log(e);
    setOpen(false);
  };
  const handleCancel = (e) => {
    console.log(e);
    setOpen(false);
  };
  return (
    <>
      <SearchBar />
      <div className="flex items-center gap-3 px-6 py-4 ">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard </span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Calendars</span>
      </div>

      <div className="px-6 py-2">
        <div className="border rounded-lg ">
          <div className="p-6 border-b ">
            <div className="flex items-center justify-between ">
              <h2 className="text-[20px] text-[#101828] font-[600]">Event Calendars</h2>

              <button onClick={showModal} className="flex items-center text-[16px] font-[500] text-[#3795F7] gap-2 p-2 border border-[#3795F7] rounded-[8px]">
                <GoPlus className="text-xl" />
                <span className="">Add Event</span>
              </button>
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-col w-[260px] p-4 gap-4 border-r">
              <span className="text-[18px] text-[#959595]">CALENDARS</span>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">All View</span>
              </div>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">Employee</span>
              </div>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">Client</span>
              </div>
            </div>

            <div className="w-full p-4">
              <CalendarDates />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Add Sessions"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          hidden: true,
        }}
        cancelButtonProps={{
          hidden: true,
        }}>
        <div className="relative flex flex-col w-full gap-2">
          <label className="text-[16px] text-[#444454]">Search Employee</label>
          <div className="relative flex w-full ">
            <IoSearch className="text-[18px] text-[#696976] absolute left-2 top-3" />
            <input className="pl-8 rounded-[8px] w-full p-2 border outline-none" placeholder="Add Employee's" />
          </div>
        </div>

        <div className="flex flex-row w-full gap-4 mt-4">
          <input type="date" className="w-full p-2 cursor-pointer text-[#959595] bg-[#F9FAFB] border rounded-[8px] outline-none" placeholder="Select Date" />
          <input type="time" className="w-full border cursor-pointer text-[#959595] bg-[#F9FAFB] p-2 rounded-[8px] outline-none" placeholder="Input Time" />
        </div>

        <div className="flex flex-row w-full gap-6 mt-12">
          <button className="bg-[#E5E7EB] w-full px-6 py-3 text-white  rounded-[8px] ">Cancel</button>
          <button className="bg-[#3795F7] w-full px-6 py-3 text-white   rounded-[8px] ">Create</button>
        </div>
      </Modal>
    </>
  );
};

export default Calendar;
