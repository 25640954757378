const ViewPdf = ({ fileUrl }) => {
  return (
    <div className="h-full w-full flex flex-col items-center">
      <div className="h-full w-full overflow-auto">
        <object data={fileUrl} type="application/pdf" width="100%" height="100%">
          <p>
            <a href={fileUrl}>Link to the PDF!</a>
          </p>
        </object>
      </div>
    </div>
  );
};

export default ViewPdf;
