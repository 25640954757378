import React, { useState, useEffect } from "react";
import { DatePicker, Input, Button, Select, message } from "antd";
import moment from "moment";
import { CiTrash } from "react-icons/ci";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { uploadMiscDoc } from "../../redux/actions/clientsAction";
import axios from "axios";
const AddMiscs = ({ currentMisc, setCurrentMisc, selectedClient, allMisc, setAllMisc, setMode, setSelectedIndexes }) => {
  const [miscData, setMiscData] = useState({
    docTitle: "",
    docDesc: "",
    doc: null,
    docType: "miscellaneous",
  });
  const [file, setFile] = useState(null);
  const { clients } = useSelector((state) => state.clients);
  const { employees } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const [dataUploading, setMiscUploading] = useState(false);
  const [dataUploaded, setMiscUploaded] = useState(false);
  const handleChange = (e) => {
    // console.log(e)
    const { name, value } = e.target;
    setMiscData({ ...miscData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    message.loading("Processing...");
    // update-Misc
    setMiscUploading(true);
    setMiscUploaded(0);
    // get file from formm
    const tmpMisc = await dispatch(
      await uploadMiscDoc(
        {
          fileName: file.name,
          fileType: file.type,
          docTitle: miscData.docTitle,
          docDesc: miscData.docDesc,
          clientId: selectedClient.id,
          requestType: "upload",
          email: selectedClient.email,
        },
        miscData.docType
      )
    );

    if (tmpMisc) {
      const presignedUrl = tmpMisc.presignedUrl;
      // Upload to S3 with axios
      const { status } = await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          setMiscUploaded(uploadPercentage);
        },
      });
      if (status === 200) {
        const Misc = await dispatch(
          await uploadMiscDoc(
            {
              dataId: tmpMisc.data.id,

              requestType: "view",
            },
            miscData.docType
          )
        );
        setMiscUploading(false);
        setMiscUploaded(100);
        // setAllMisc([tmpMisc, ...allMisc]);
        setMiscData({ docTitle: "", docDesc: "", doc: null });
        setSelectedIndexes({
          Misc: 0,
        });
        setCurrentMisc(Misc);
        setAllMisc([Misc, ...allMisc]);
        setMode("view");
      }
    }
  };
  const years = Array.from({ length: 2035 - 2000 + 1 }, (v, k) => k + 2000);

  return (
    <div className="col-span-6 p-4 rounded-[8px] border shadow-md h-[80vh] overflow-y-scroll">
      <div className="w-full p-2 mt-3 ">
        <div className="flex items-center justify-between">
          <h1 className="text-[24px] font-bold text-[#161D20]">{selectedClient.fullName}</h1>
        </div>
        <div className="flex flex-row gap-4 mt-0">
          <span className="text-[16px]  text-[#444454CC]">{selectedClient.uci}</span>
          {selectedClient?.email && <p className="text-[#444454] text-[1rem]">{selectedClient?.email && selectedClient.email}</p>}
        </div>
        {/* role */}
      </div>
      <form onSubmit={handleSave}>
        <div className="w-full flex gap-4 items-center mt-8">
          <label htmlFor="docTitle" className="text-[#444454] text-[14px] w-1/4">
            Document Type
          </label>
          <Select name="docType" value={miscData.docType} onChange={(value) => setMiscData({ ...miscData, docType: value })} placeholder="Document Type" className="w-full" required>
            <Select.Option value="miscellaneous">Miscellaneous</Select.Option>
            <Select.Option value="isps">ISPs</Select.Option>
            <Select.Option value="assessments">Assessments</Select.Option>
            <Select.Option value="assessments">Assessments</Select.Option>
          </Select>
        </div>
        <div className="w-full flex gap-4 items-center mt-4">
          <label htmlFor="docTitle" className="text-[#444454] text-[14px] w-1/4">
            Document Title
            <br />
            <span className="text-xs">(Optional)</span>
          </label>
          <Input name="docTitle" value={miscData.docTitle} onChange={handleChange} placeholder="Document Title" className="" required={false} />
        </div>
        <div className="w-full flex mt-4 gap-4 items-center">
          <label htmlFor="docDesc" className="text-[#444454] text-[14px] w-1/4">
            Document Description
            <br />
            <span className="text-xs">(Optional)</span>
          </label>
          <Input.TextArea name="docDesc" value={miscData.docDesc} onChange={handleChange} placeholder="Document Description" className="" required={false} />
        </div>
        <div className="w-full flex mt-4 gap-4 items-center">
          <label htmlFor="Misc" className="text-[#444454] text-[14px] w-1/4">
            Document
          </label>
          <input type="file" name="Misc" onChange={(e) => setFile(e.target.files[0])} className="ml-0" required />
        </div>
        <div className="flex gap-4 items-center mt-6">
          <button type="button" className="text-sm" onClick={() => setMode("view")}>
            Cancel
          </button>
          <Button type="primary" htmlType="submit" className="mt-3 mb-5 disabled:bg-primary disabled:text-white" disabled={dataUploading}>
            {!dataUploading ? "Save Changes" : dataUploaded}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddMiscs;
