import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import EmployeeData from "../../components/EmployeeData";
import { useNavigate } from "react-router-dom";
import { getAll } from "../../redux/actions/employeesAction";
import { useDispatch, useSelector } from "react-redux";
const Employee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const handleClick = () => {
    navigate("/add-employee");
  };
  useEffect(() => {
    dispatch(getAll());
  }, []);

  return (
    <>
      <SearchBar />
      <div className="flex items-center gap-3 px-6 py-2 ">
        <div className="flex items-center gap-2 cursor-pointer text-sm text-[#696976]">
          <FaHome />
          <span className="cursor-pointer" onClick={() => navigate("/")}>
            Dashboard{" "}
          </span>
        </div>
        <span className="text-sm text-[#696976]">/ </span>

        <span className="cursor-pointer text-sm font-semibold text-[#444454]">Employee </span>
      </div>
      <h1 className="px-6 py-2 text-[#161D20] font-bold text-[24px]">Employee </h1>

      <div className="px-6 py-2">
        <div className="border rounded-lg ">
          <div className="p-4 px-6 border-b ">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <h2 className="text-[20px] text-[#101828] font-[600]">All Employees</h2>
              {user?.role == "admin" && (
                <button className="flex items-center text-sm font-[500] text-[#3795F7] gap-2 p-1 md:p-2 border border-[#3795F7] rounded-[8px]" onClick={handleClick}>
                  <GoPlus className="text-xl" />
                  <span className="">Add new Employee</span>
                </button>
              )}
            </div>
          </div>

          <div className="w-full">
            <EmployeeData />
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee;
