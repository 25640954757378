import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { FiLayers, FiLogOut } from "react-icons/fi";
import { LuCopyCheck } from "react-icons/lu";
import { BiPieChartAlt2 } from "react-icons/bi";
import { CgSupport } from "react-icons/cg";
import { SlSettings } from "react-icons/sl";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/actions/auth";
const Sidebar = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menus = [
    { name: "Dashboard", link: "/dashboard", icon: FaHome, roles: ["admin", "employee", "auditor"] },
    { name: "Employees", link: "/employee", icon: FiLayers, roles: ["admin", "auditor"] },
    { name: "Clients", link: "/client", icon: LuCopyCheck, roles: ["admin", "auditor"] },
    { name: "Report/Audit ", link: "/report", icon: BiPieChartAlt2, roles: ["admin", "auditor"] },
    // { name: "Support", link: "/support", icon: CgSupport },
    { name: "Settings", link: "/settings", icon: SlSettings, roles: ["admin", "employee"] },
  ];
  // const
  const [open, setOpen] = useState(true);
  const handleLogout = async () => {
    await dispatch(logOut());
    // navigate("/signin");
    window.location.reload();
  };
  return (
    <section className={`flex ${open ? "w-[17rem]" : "w-[5rem]"} rounded-md shadow-lg`}>
      <div className={`flex flex-col justify-between h-screen fixed top-0 duration-500 text-gray-100 z-1500 ${open ? "w-[14rem]" : "w-auto"}`}>
        <div>
          <div className="flex items-center justify-between py-3 pl-4 mt-2 ">
            <span className={`text-xl text-black w-32 font-bold ${!open ? "hidden" : ""}`} alt="logo">
              <img src="/images/logo_full.jpeg" alt="" />
            </span>
            <div className="flex items-center justify-center w-10 h-10 rounded-full shadow-md hover:shadow-xl cursor-pointer mr-5">
              <div className="flex items-center justify-center w-6 h-6 hover:w-7 hover:h-7 rounded-full bg-[#3795F7]">
                <FaAngleLeft size={18} className={`cursor-pointer text-white ${!open ? "rotate-180" : ""}`} onClick={() => setOpen(!open)} />
              </div>
            </div>
          </div>

          <div className="relative flex flex-col gap-2 px-4 py-2 mt-  w-full">
            {menus?.map(
              (menu, i) =>
                menu.roles.includes(user?.role) && (
                  <Link
                    to={menu?.link}
                    key={i}
                    className={` ${menu?.margin && "mt-4"} group flex items-center ${!open ? "justify-center" : " gap-3"}  text-sm  font-medium p-2 hover:bg-gray-100 rounded-md`}>
                    <div>
                      {React.createElement(menu?.icon, {
                        size: "20",
                        color: "#444454",
                      })}
                    </div>
                    <h2
                      style={{
                        transitionDelay: `${i + 2}00ms`,
                      }}
                      className={`whitespace-pre text-sm duration-500 text-[#444454] ${!open && "hidden opacity-0 translate-x-28 overflow-hidden"}`}>
                      {menu?.name}
                    </h2>
                  </Link>
                )
            )}
          </div>
        </div>

        <div className="relative flex flex-col mt-auto gap-2 px-4 py-2   w-full">
          <Link to={"#"} onClick={handleLogout} className={` mt-4 group flex items-center ${!open ? "justify-center" : " gap-3"}  text-sm  font-medium p-2 hover:bg-gray-100 rounded-md`}>
            <div>
              {React.createElement(FiLogOut, {
                size: "20",
                color: "#444454",
              })}
            </div>
            <h2
              style={{
                transitionDelay: `600ms`,
              }}
              className={`whitespace-pre text-sm duration-500 text-[#444454] ${!open && "hidden opacity-0 translate-x-28 overflow-hidden"}`}>
              Logout
            </h2>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
