import React from "react";
import { useNavigate } from "react-router-dom";
const ClientSideBar = ({ tab, uuid }) => {
  const navigate = useNavigate();
  const tabs = [
    { name: "Face Sheet", link: `/client/face-sheet/${uuid}` },
    { name: "Assessments", link: `/client/assessments/${uuid}` },
    { name: "Session", link: `/client/sessions/${uuid}` },
    { name: "ISPs", link: `/client/isps/${uuid}` },
    { name: "Quarterly Summary", link: `/client/quarterly-summary/${uuid}` },
    { name: "SIRs", link: `/client/sir/${uuid}` },
    { name: "Calendar / MAR", link: `/client/calendar/${uuid}` },
    { name: "Miscellaneous", link: `/client/misc/${uuid}` },
  ];
  return (
    <div className="col-span-2 text-center py-1 px-0">
      {tabs.map((t, i) => (
        <div key={i} className={`border-y py-2 w-full hover:shadow-md cursor-pointer hover:bg-sky-50 rounded ${tab === t.name ? "bg-sky-50" : ""}`} onClick={() => navigate(t.link)}>
          <p>{t.name}</p>
        </div>
      ))}
    </div>
  );
};

export default ClientSideBar;
