const initState = {
  loading: false,
  sessions: [],
  selectedSession: null,
  selectedSessionId: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        loading: !state.loading,
      };
    case "SET_ALL_SESSIONS":
      return {
        ...state,
        sessions: action.payload,
      };
    default:
      return state;
  }
};
