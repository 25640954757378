import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import ClientSideBar from "../../components/ClientSideBar";
import { FaHome } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const FaceSheet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { selectedClient, clients } = useSelector((state) => state.clients);

  const [imagePreviewUrl, setImagePreviewUrl] = useState(selectedClient?.imageUrl || "");
  const [clientData, setClientData] = useState(selectedClient ? selectedClient : null);

  useEffect(() => {
    if (uuid && !selectedClient && clients.length > 0) {
      dispatch({
        type: "SET_SELECTED_CLIENT_UUID",
        payload: uuid,
      });
      return;
    }
    if (!selectedClient) return;
    for (const el of document?.forms[0]?.elements) {
      if (el.name && el.name != "" && selectedClient[el.name]) {
        console.log(el.name);
        console.log(selectedClient[el.name]);
        el.value = selectedClient[el.name];
      }
    }
    setClientData(selectedClient);

    if (selectedClient.imageUrl) {
      setImagePreviewUrl(selectedClient.imageUrl);
    }
  }, [selectedClient, clients]);

  return (
    selectedClient && (
      <>
        {/* <SearchBar /> */}
        <div className="flex flex-wrap items-center gap-3 px-6 pt-6 ">
          <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
            <FaHome />
            <span className="cursor-pointer">Dashboard </span>
          </div>
          <span className="text-[16px] text-[#696976]">/ </span>

          <span className="cursor-pointer text-[16px] text-[#696976]" onClick={() => navigate("/client")}>
            Client
          </span>
          <span className="text-[16px] text-[#696976]">/ </span>

          <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Face Sheet</span>
        </div>
        {selectedClient && (
          <div className={`flex items-center justify-between mx-8 w-1/2 py-3 px-4 `}>
            <div className="flex items-center gap-2  w-full">
              <div className="aspect-w-1 aspect-h-1 overflow-hidden  h-16 w-16 rounded-full">
                <img src={selectedClient.imageUrl ? selectedClient.imageUrl : "/images/user.png"} alt="employee" className="object-cover w-full h-full" />
              </div>
              <div>
                <h1 className="text-[#161D20] text-[1.5rem] -mb-1 font-bold">{selectedClient.fullName}</h1>
                <span className="text-[#444454] text-[1rem]">{selectedClient.uci}</span>
                {selectedClient?.email && <span className="text-[#444454] text-[1rem] "> - {selectedClient?.email && selectedClient.email}</span>}
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-10 gap-1 px-1 h-[80vh]">
          <ClientSideBar tab={"Face Sheet"} uuid={selectedClient.uuid} />
          <div className=" col-span-8 p-4 rounded-[8px] border shadow-md h-[80vh] overflow-y-scroll">
            <form action="" className="w-full">
              {clientData && (
                <>
                  <div className="flex flex-col w-full md:flex-row mt-4">
                    <div className="w-full">
                      <div className="flex flex-col ">
                        <div className="md:p-0 my-4 w-full ">
                          <div className="relative text-center w-full">
                            <img
                              src={imagePreviewUrl ? imagePreviewUrl : "/images/user.png"}
                              onError={() => "../../assets/user.png"}
                              alt="Preview"
                              className="mx-auto"
                              style={{ width: 200, position: "relative" }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="p-6 grid items-center justify-between flex-wrap grid-cols-2 gap-4 border rounded-[10px] shadow-md"> */}
                      <div className="p-6 grid items-center justify-between gap-4 md:grid-cols-2">
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">First Name: </label>

                          <p>{clientData.firstName}</p>
                        </div>

                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Last Name: </label>

                          <p>{clientData.lastName}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Email: </label>

                          <p>{clientData.email}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Phone Number: </label>

                          <p>{clientData.number}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Zip Code: </label>

                          <p>{clientData.zipCode}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">UCI: </label>

                          <p>{clientData.uci}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Client Start Date: </label>

                          <p>{clientData.startDate}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Date of Birth: </label>
                          <p>{clientData.dob}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Height: </label>
                          <p>{clientData.height}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Weight: </label>
                          <p>{clientData.weight}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Hair Color: </label>

                          <p>{clientData.hairColor}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Eye Color: </label>
                          <p>{clientData.eyeColor}</p>
                        </div>

                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Gender: </label>
                          <p>{clientData.gender}</p>
                        </div>
                        <div className="flex items-center gap-2 border-b p-1 px-2">
                          <label className="text-[16px] font-[400] text-[#444454]">Is Medical: </label>
                          <p>{clientData.isMedical ? "Yes" : "No"}</p>
                        </div>

                        {/* Check if is medical is ticked and show next element */}
                        {clientData?.isMedical && (
                          <div className="flex items-center gap-2 border-b p-1 px-2">
                            <label className="text-[16px] font-[400] text-[#444454]">Medical Number: </label>

                            <p>{clientData.medicalNumber}</p>
                          </div>
                        )}
                      </div>

                      <div className="mx-6 flex items-center gap-2 border-b p-1">
                        <label className="text-[16px] font-[400] text-[#444454]">Address: </label>
                        <p>{clientData.address}</p>
                      </div>

                      <div className="w-full px-4 my-6 space-y-4">
                        <div className="w-full flex justify-between">
                          <h2 className="my-1">Emergency Contacts</h2>
                        </div>
                        {(!clientData?.emergencyContacts || clientData?.emergencyContacts?.length === 0) && <p className="text-gray-700 text-sm">No emergency contacts found</p>}
                        {clientData?.emergencyContacts?.map((ec, index) => (
                          <div key={ec.id} className="p-4 w-full border rounded-[10px] shadow-md mx-2 grid items-center justify-between gap-4 md:grid-cols-2">
                            <div className="flex items-center gap-2">
                              <label className="text-[16px] font-[400] text-[#444454]">Name</label>
                              <p>{ec.name}</p>
                            </div>
                            <div className="flex items-center gap-2">
                              <label className="text-[16px] font-[400] text-[#444454]">Number: </label>
                              <p>{ec.number}</p>
                            </div>
                            <div className="flex items-center gap-2">
                              <label className="text-[16px] font-[400] text-[#444454]">Relationship: </label>

                              <p>{ec.relationship}</p>
                            </div>
                            <div className="flex items-center gap-2">
                              <label className="text-[16px] font-[400] text-[#444454]">Address: </label>
                              <p>{ec.address}</p>
                            </div>
                            <p></p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </>
    )
  );
};

export default FaceSheet;
