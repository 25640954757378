const initState = {
  loading: false,
  clients: [],
  selectedClient: null,
  selectedClientId: null,
  selectedClientCalendar: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        loading: !state.loading,
      };
    case "SET_ALL_CLIENTS":
      return {
        ...state,
        clients: action.payload,
      };
    case "CREATE_CLIENT":
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };
    case "UPDATE_CLIENT":
      return {
        ...state,
        clients: state.clients.map((emp) => {
          if (emp.id === action.payload.id) {
            return action.payload;
          }
          return emp;
        }),
      };
    case "SET_SELECTED_CLIENT":
      return {
        ...state,
        selectedClientId: action.payload.id,
        selectedClient: action.payload,
      };
    case "SET_SELECTED_CLIENT_UUID":
      // payload is uuid find using that
      return {
        ...state,
        selectedClientId: state.clients.find((client) => client.uuid === action.payload).id,
        selectedClient: state.clients.find((client) => client.uuid === action.payload),
      };
    case "SET_SELECTED_CLIENT_CALENDAR":
      return {
        ...state,
        selectedClientCalendar: action.payload,
      };
    default:
      return state;
  }
};
