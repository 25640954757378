import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import ClientSideBar from "../../components/ClientSideBar";
import { FaHome } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import authAxios from "../../redux/actions/AxiosInstance/authenticated";
import { getAllSIRs } from "../../redux/actions/clientsAction";
import View from "./View";
import EditView from "./Edit";
import AddSIR from "./Add";
const SIRs = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { selectedClient, clients } = useSelector((state) => state.clients);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    // console.log(selectedClient);
    if (uuid && !selectedClient && clients.length > 0) {
      dispatch({ type: "SET_SELECTED_CLIENT_UUID", payload: uuid });
      return;
    }
    if (!selectedClient) return;
  }, [selectedClient, clients]);

  const [selectedIndexes, setSelectedIndexes] = useState({ employee: null, sir: null });
  const [currentSIR, setCurrentSIR] = useState(null);
  const [mode, setMode] = useState("view");
  const [allSIRs, setAllSIRs] = useState([]);
  const [empLoading, setEmpLoading] = useState(false);
  const [sirLoading, setSIRLoading] = useState(false);
  const handleSelectEmp = async (id) => {
    setEmpLoading(true);
    setCurrentSIR(null);
    setSelectedIndexes({ employee: null, sir: null });
    // call api
    const sirs = await dispatch(await getAllSIRs(id));
    setAllSIRs(sirs);
    if (sirs.length > 0) {
      setCurrentSIR(sirs[0]);
      setSelectedIndexes({ ...selectedIndexes, sir: 0 });
    }
    // console.log(resdata.data.SIRs);
    setEmpLoading(false);
  };

  useEffect(() => {
    if (selectedClient && selectedClient?.id) {
      handleSelectEmp(selectedClient.id);
    }
  }, [selectedClient]);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  return (
    selectedClient && (
      <div className="overflow-hidden">
        <div className="flex items-center gap-3 px-6 pt-6 ">
          <div className="flex items-center gap-2 cursor-pointer text-[16px] font-[400] text-[#696976]">
            <FaHome />
            <span className="cursor-pointer">Dashboard </span>
          </div>
          <span className="text-[16px] text-[#696976]">/ </span>
          <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">Client</span>

          <span className="text-[16px] text-[#696976]">/ </span>

          <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">SIR Details</span>
        </div>
        {selectedClient && (
          <div className={`flex items-center justify-between mx-8 w-1/2 py-3 px-4 `}>
            <div className="flex items-center gap-2  w-full">
              <div className="aspect-w-1 aspect-h-1 overflow-hidden  h-16 w-16 rounded-full">
                <img src={selectedClient.imageUrl ? selectedClient.imageUrl : "/images/user.png"} alt="employee" className="object-cover w-full h-full" />
              </div>
              <div>
                <h1 className="text-[#161D20] text-[1.5rem] -mb-1 font-bold">{selectedClient.fullName}</h1>
                <span className="text-[#444454] text-[1rem]">{selectedClient.uci}</span>
                {selectedClient?.email && <span className="text-[#444454] text-[1rem] "> - {selectedClient?.email && selectedClient.email}</span>}
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-10 gap-1 px-1 h-[85vh] overflow-hidden">
          <ClientSideBar tab={"SIRs"} uuid={selectedClient.uuid} />
          {/* left side */}
          <div className="col-span-2 gap-4 rounded-[8px] border shadow-md h-[80vh] overflow-scroll">
            {/* <div className="border shadow-md rounded-[8px] "> */}
            {allSIRs.map((item, index) => (
              <div
                key={index}
                onClick={async () => {
                  setCurrentSIR(null);
                  setSIRLoading(true);
                  // sleep for 1 sec
                  await new Promise((resolve) => setTimeout(resolve, 250));

                  setSelectedIndexes({
                    ...selectedIndexes,
                    sir: index,
                  });

                  setSIRLoading(false);
                  setCurrentSIR(item);
                  setMode("view");
                }}
                className={`flex items-center justify-between py-3 w-full text-center border shadow-sm hover:shadow-md cursor-pointer ${index == 0 && "rounded-t-[8px]"} ${
                  selectedIndexes.sir === index && "bg-gray-200"
                }`}>
                <div className="flex items-center text-center w-full">
                  {/* {item.id}
                  <img src={item?.client?.imageUrl ? item?.client?.imageUrl : "/images/user.png"} alt="employee" className="w-10 h-10 rounded-full" /> */}
                  <div className="text-center w-full">
                    <p className="text-sm">{item?.docTitle}</p>
                  </div>
                </div>
              </div>
            ))}

            {user.role == "admin" && (
              <div
                onClick={async () => {
                  setCurrentSIR(null);
                  setSIRLoading(true);
                  // sleep for 1 sec
                  await new Promise((resolve) => setTimeout(resolve, 250));

                  setSelectedIndexes({
                    ...selectedIndexes,
                    sir: allSIRs.length,
                  });

                  setSIRLoading(false);
                  setCurrentSIR(null);
                  setMode("add");
                }}
                className={`flex items-center justify-between py-3 w-full text-center border shadow-sm hover:shadow-md cursor-pointer ${allSIRs.length == 0 && "rounded-t-[8px]"} ${
                  selectedIndexes.sir === allSIRs.length && "bg-gray-200"
                }`}>
                <div className="flex items-center text-center w-full" onClick={() => setMode("add")}>
                  {/* {item.id}
                  <img src={item?.client?.imageUrl ? item?.client?.imageUrl : "/images/user.png"} alt="employee" className="w-10 h-10 rounded-full" /> */}
                  <div className="text-center w-full">
                    <p className="text-sm">+ Add New</p>
                  </div>
                </div>
              </div>
            )}
            {empLoading && <Loader className="mt-40" />}

            {!empLoading && allSIRs.length == 0 && (
              <div className="text-center w-full my-auto mt-12">
                <p className="text-sm text-gray-700">No SIR Found</p>
              </div>
            )}
          </div>
          {/* right side */}
          {sirLoading && <Loader className="mt-40" />}
          {currentSIR && mode == "edit" && (
            <EditView currentSIR={currentSIR} setCurrentSIR={setCurrentSIR} selectedClient={selectedClient} allSIRs={allSIRs} setAllSIRs={setAllSIRs} setMode={setMode} />
          )}
          {currentSIR && mode == "view" && <View currentSIR={currentSIR} setCurrentSIR={setCurrentSIR} selectedClient={selectedClient} setMode={setMode} />}
          {mode == "add" && (
            <AddSIR
              currentSIR={currentSIR}
              setCurrentSIR={setCurrentSIR}
              selectedClient={selectedClient}
              allSIRs={allSIRs}
              setAllSIRs={setAllSIRs}
              setMode={setMode}
              setSelectedIndexes={setSelectedIndexes}
            />
          )}
        </div>
      </div>
    )
  );
};

export default SIRs;
