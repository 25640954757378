import { useLocation, Outlet, Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
// import BottomBar from "../components/BottomBar";
import { getUserInfo } from "../redux/actions/auth";
import { getAll } from "../redux/actions/employeesAction";
import { getAllClients } from "../redux/actions/clientsAction";
import React, { useEffect } from "react";
import { connect } from "react-redux";
export const Index = (props) => {
  const { getUserInfo, getAll, getAllClients } = props;
  const location = useLocation();
  const currentRoute = location.pathname.slice(1);
  const [sidebarClosingStatus, setSidebarClosingStatus] = React.useState(false);
  useEffect(() => {
    getUserInfo();
    getAll();
    getAllClients();
    console.log(props);
  }, [props]);
  return (
    <div className="flex font-[Red Hat Display] bg-[#fefefe]">
      <Sidebar />
      {/* <Sidebar sidebarClosingStatus={sidebarClosingStatus} setSidebarClosingStatus={setSidebarClosingStatus} /> */}
      {/* <div className={`w-full text-left  ${sidebarClosingStatus ? `md:ml-[240px]` : `md:ml-[5.2rem]`}`}> */}
      <div id="indexLayout" className="relative w-full min-h-screen overflow-y-auto">
        <Outlet />
      </div>
      {/* </div> */}
      {/* <BottomBar sidebarClosingStatus={sidebarClosingStatus} setSidebarClosingStatus={setSidebarClosingStatus} /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getUserInfo, getAll, getAllClients };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
