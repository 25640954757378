import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { createClient } from "../../redux/actions/clientsAction";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import ImageUploader from "../../components/ImageUploader"; // Import the ImageUploader component

const AddClient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientData, setClientData] = useState({
    isMedical: false,
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    address: "",
    zipCode: "",
    uci: "",
    startDate: null,
    emergencyContacts: [
      {
        id: uuidv4(),
        name: "",
        number: "",
        relationship: "",
        address: "",
      },
    ],
    height: "",
    weight: "",
    hairColor: "",
    eyeColor: "",
    dob: null,
    gender: null,
    isMedical: false,
    medicalNumber: "",
  });

  const handleChange = (e) => {
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };

  const handleClientEmployee = async (e) => {
    e.preventDefault();
    let formData = {};
    for (const el of e.currentTarget.elements) {
      if (el.name && el.name !== "") {
        formData[el.name] = el.value;
      }
    }
    formData.isMedical = clientData.isMedical;
    formData.emergencyContacts = clientData.emergencyContacts;

    try {
      const createClientResponse = await dispatch(createClient(formData));
      const clientId = createClientResponse.id; // Ensure this matches your actual response structure

      navigate("/client");
    } catch (error) {
      console.error("Failed to create client or upload image", error);
    }
  };

  const handleImageUpload = (imageKey) => {
    setClientData({ ...clientData, imageKey });
  };

  return (
    <>
      <SearchBar />
      <div className="flex flex-wrap items-center gap-3 px-6 py-4">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard </span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] text-[#696976]" onClick={() => navigate("/client")}>
          Client
        </span>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Add Client</span>
      </div>
      <h1 className="px-6 py-2 text-[#161D20] font-bold text-[24px]">Add Client</h1>

      <form action="" onSubmit={handleClientEmployee}>
        <div className="flex flex-col w-full md:flex-row mt-4">
          <div className="px-4 md:w-2/3 border rounded-[10px] shadow-md mx-2">
            <div className="p-6 grid items-center justify-between gap-4 md:grid-cols-2">
              <div className="flex flex-col w-full gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  onChange={handleChange}
                  value={clientData.firstName}
                  className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={clientData.lastName}
                  placeholder="Enter your last name"
                  className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Email</label>
                <input
                  type="email"
                  required={false}
                  name="email"
                  onChange={handleChange}
                  value={clientData.email}
                  placeholder="Enter your email"
                  className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Phone Number</label>
                <input
                  type="text"
                  name="number"
                  required={true}
                  onChange={handleChange}
                  value={clientData.number}
                  placeholder="Enter your number"
                  className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Zip Code</label>
                <input
                  type="text"
                  name="zipCode"
                  onChange={handleChange}
                  value={clientData.zipCode}
                  placeholder="Enter your zip code"
                  className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">UCI</label>
                <input type="text" required={true} name="uci" placeholder="Enter Client UCI" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Client Start Date</label>
                <input name="startDate" type="date" placeholder="Client Start Date" className="w-full px-4 py-3 outline-none border rounded-[8px] cursor-pointer text-[#444454]" />
              </div>
              <div className="flex items-center pt-6 gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Is Medical: </label>
                <input
                  name="isMedical"
                  onChange={(e) => {
                    setClientData({ ...clientData, isMedical: e.target.checked });
                  }}
                  type="checkbox"
                  className="px-4 py-3 outline-none border rounded-[8px] cursor-pointer text-[#444454]"
                />
              </div>

              {clientData?.isMedical && (
                <div className="flex flex-col gap-2">
                  <label className="text-[16px] font-[400] text-[#444454]">Medical Number</label>
                  <input type="text" name="medicalNumber" placeholder="Enter your medical Number" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
                </div>
              )}
            </div>

            <div className="px-6 flex flex-col gap-2">
              <label className="text-[16px] font-[400] text-[#444454]">Address</label>
              <textarea type="text" name="address" placeholder="Enter your address" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
            </div>
            <div className="p-6 grid items-center justify-between gap-4 md:grid-cols-2">
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Height</label>
                <input type="text" name="height" placeholder="Enter your height" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Weight</label>
                <input type="text" name="weight" placeholder="Enter your weight" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Hair Color</label>
                <input type="text" name="hairColor" placeholder="Enter your hair color" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Eye Color</label>
                <input type="text" name="eyeColor" placeholder="Enter your eye color" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Date of Birth</label>
                <input type="date" name="dob" placeholder="Enter your date of birth" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Gender</label>
                <select name="gender" className="w-full bg-white px-2 border h-12 outline-none rounded-[8px] font-[500] text-[#444454]">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="w-full px-4 mb-6 space-y-4">
              <div className="w-full flex justify-between">
                <h2 className="my-4">Emergency Contacts</h2>
                <p
                  onClick={() => {
                    setClientData({
                      ...clientData,
                      emergencyContacts: [
                        ...clientData.emergencyContacts,
                        {
                          id: uuidv4(),
                          name: "",
                          number: "",
                          relationship: "",
                          address: "",
                        },
                      ],
                    });
                  }}
                  className="my-4 hover:cursor-pointer hover:opacity-60 hover:underline">
                  Add More
                </p>
              </div>

              {clientData.emergencyContacts.map((ec, index) => (
                <div key={ec.id} className="p-4 w-full border rounded-[10px] shadow-md mx-2 grid items-center justify-between gap-4 md:grid-cols-2">
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Name</label>
                    <input type="text" value={ec.name} placeholder="Enter your name" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Number</label>
                    <input type="text" value={ec.number} placeholder="Enter your number" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Relationship</label>
                    <input type="text" value={ec.relationship} placeholder="Enter your relationship" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Address</label>
                    <textarea type="text" value={ec.address} placeholder="Enter your address" className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]" />
                  </div>
                  <p></p>
                  <DeleteOutlined
                    onClick={() => {
                      const newEmergencyContacts = clientData.emergencyContacts.filter((_, i) => i !== index);
                      setClientData({ ...clientData, emergencyContacts: newEmergencyContacts });
                    }}
                    className="ml-auto text-red-500"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* <div className="flex flex-col md:w-1/3 mr-4">
            <ImageUploader
              imageKeyFieldId="imageKey"
              modelId={uuidv4()} // Generate a new UUID for the new client
              modelType="clients"
              initialImageUrl=""
              onImageUpload={handleImageUpload}
            />
          </div> */}
        </div>
        <div className="px-6 ">
          <button type="submit" className="bg-[#3795F7] px-6 py-3 text-white font-[500] rounded-[8px] mt-6">
            Add Client
          </button>
        </div>
      </form>
    </>
  );
};

export default AddClient;
