import React, { useEffect, useState, useCallback } from "react";
import SearchBar from "../../components/SearchBar";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { FaHome } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateClient } from "../../redux/actions/clientsAction";
import { v4 as uuidv4 } from "uuid";
import ImageUploader from "../../components/ImageUploader"; // Import the ImageUploader component

const EditClient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { selectedClient, clients } = useSelector((state) => state.clients);
  const [clientData, setClientData] = useState(selectedClient ? selectedClient : null);

  useEffect(() => {
    if (uuid && !selectedClient && clients.length > 0) {
      dispatch({
        type: "SET_SELECTED_CLIENT_UUID",
        payload: uuid,
      });
      return;
    }
    if (!selectedClient) return;
    for (const el of document?.forms[0]?.elements) {
      if (el.name && el.name !== "" && selectedClient[el.name]) {
        el.value = selectedClient[el.name];
      }
    }
    setClientData(selectedClient);
  }, [selectedClient, clients]);

  const handleChange = (e) => {
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };

  const handleEmergencyContactChange = (e, index, name) => {
    const newEmergencyContacts = clientData.emergencyContacts.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [name]: e.target.value,
        };
      }
      return item;
    });
    setClientData({ ...clientData, emergencyContacts: newEmergencyContacts });
  };

  const handleClientSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    for (const el of e.currentTarget.elements) {
      if (el.name && el.name !== "") {
        data[el.name] = el.value;
      }
    }

    const res = await dispatch(
      await updateClient({
        ...data,
        emergencyContacts: clientData?.emergencyContacts,
        isMedical: clientData?.isMedical,
        imageKey: clientData?.imageKey,
        id: clientData.id,
      })
    );
    if (res) {
      navigate("/client");
    }
  };

  const handleImageUpload = (imageKey) => {
    setClientData({ ...clientData, imageKey });
  };

  return (
    <>
      <SearchBar />
      <div className="flex flex-wrap items-center gap-3 px-6 py-4">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard </span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] text-[#696976]" onClick={() => navigate("/client")}>
          Client
        </span>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Edit Client Information</span>
      </div>

      <h1 className="px-6 py-2 text-[#161D20] font-bold text-[24px]">Edit Client</h1>
      <form action="" onSubmit={handleClientSubmit}>
        {clientData && (
          <>
            <div className="flex flex-col w-full md:flex-row mt-4">
              <div className="px-4 md:w-2/3 border rounded-[10px] shadow-md mx-2">
                <div className="p-6 grid items-center justify-between gap-4 md:grid-cols-2">
                  <div className="flex flex-col w-full gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Enter your first name"
                      onChange={handleChange}
                      value={clientData.firstName}
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={clientData.lastName}
                      placeholder="Enter your last name"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Email</label>
                    <input
                      type="email"
                      required={false}
                      name="email"
                      onChange={handleChange}
                      value={clientData.email}
                      placeholder="Enter your email"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Phone Number</label>
                    <input
                      type="text"
                      name="number"
                      required={true}
                      onChange={handleChange}
                      value={clientData.number}
                      placeholder="Enter your number"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      onChange={handleChange}
                      value={clientData.zipCode}
                      placeholder="Enter your zip code "
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">UCI</label>
                    <input
                      type="text"
                      required={true}
                      value={clientData.uci}
                      onChange={handleChange}
                      name="uci"
                      placeholder="Enter your state "
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Client Start Date</label>
                    <input
                      name="startDate"
                      type="date"
                      value={clientData.startDate}
                      onChange={handleChange}
                      placeholder="Client Start Date"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] cursor-pointer text-[#444454]"
                    />
                  </div>
                  <div className="flex items-center pt-6 gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Is Medical: </label>
                    <input
                      name="isMedical"
                      checked={clientData.isMedical}
                      onChange={(e) => {
                        setClientData({ ...clientData, isMedical: e.target.checked });
                      }}
                      type="checkbox"
                      className="px-4 py-3 outline-none border rounded-[8px] cursor-pointer text-[#444454]"
                    />
                  </div>

                  {clientData?.isMedical && (
                    <div className="flex flex-col gap-2">
                      <label className="text-[16px] font-[400] text-[#444454]">Medical Number</label>
                      <input
                        type="text"
                        value={clientData.medicalNumber}
                        onChange={handleChange}
                        name="medicalNumber"
                        placeholder="Enter your medical Number"
                        className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                      />
                    </div>
                  )}
                </div>

                <div className="px-6 flex flex-col gap-2">
                  <label className="text-[16px] font-[400] text-[#444454]">Address</label>
                  <textarea
                    type="text"
                    name="address"
                    onChange={handleChange}
                    value={clientData.address}
                    placeholder="Enter your address"
                    className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                  />
                </div>
                <div className="p-6 grid items-center justify-between gap-4 md:grid-cols-2">
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Height</label>
                    <input
                      type="text"
                      name="height"
                      onChange={handleChange}
                      value={clientData.height}
                      placeholder="Enter your height"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Weight</label>
                    <input
                      type="text"
                      name="weight"
                      onChange={handleChange}
                      value={clientData.weight}
                      placeholder="Enter your weight"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Hair Color</label>
                    <input
                      type="text"
                      name="hairColor"
                      value={clientData.hairColor}
                      onChange={handleChange}
                      placeholder="Enter your hair color"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Eye Color</label>
                    <input
                      type="text"
                      name="eyeColor"
                      onChange={handleChange}
                      value={clientData.eyeColor}
                      placeholder="Enter your eye color"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Date of Birth</label>
                    <input
                      type="date"
                      name="dob"
                      onChange={handleChange}
                      value={clientData.dob}
                      placeholder="Enter your date of birth"
                      className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Gender</label>
                    <select
                      name="gender"
                      value={clientData.gender}
                      onChange={(e) => {
                        setClientData({
                          ...clientData,
                          gender: e.target.value,
                        });
                      }}
                      className="w-full bg-white px-2 border h-12 outline-none rounded-[8px] font-[500] text-[#444454]">
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="w-full px-4 mb-6 space-y-4">
                  <div className="w-full flex justify-between">
                    <h2 className="my-4">Emergency Contacts</h2>
                    <p
                      onClick={() => {
                        setClientData({
                          ...clientData,
                          emergencyContacts: [
                            ...clientData.emergencyContacts,
                            {
                              id: uuidv4(),
                              name: "",
                              number: "",
                              relationship: "",
                              address: "",
                            },
                          ],
                        });
                      }}
                      className="my-4 hover:cursor-pointer hover:opacity-60 hover:underline">
                      Add More
                    </p>
                  </div>

                  {clientData?.emergencyContacts?.map((ec, index) => (
                    <div key={ec.id} className="p-4 w-full border rounded-[10px] shadow-md mx-2 grid items-center justify-between gap-4 md:grid-cols-2">
                      <div className="flex flex-col gap-2">
                        <label className="text-[16px] font-[400] text-[#444454]">Name</label>
                        <input
                          type="text"
                          value={ec.name}
                          onChange={(e) => {
                            handleEmergencyContactChange(e, index, "name");
                          }}
                          placeholder="Enter your name"
                          className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-[16px] font-[400] text-[#444454]">Number</label>
                        <input
                          type="text"
                          value={ec.number}
                          onChange={(e) => {
                            handleEmergencyContactChange(e, index, "number");
                          }}
                          placeholder="Enter your number"
                          className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-[16px] font-[400] text-[#444454]">Relationship</label>
                        <input
                          type="text"
                          value={ec.relationship}
                          onChange={(e) => {
                            handleEmergencyContactChange(e, index, "relationship");
                          }}
                          placeholder="Enter your relationship"
                          className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-[16px] font-[400] text-[#444454]">Address</label>
                        <textarea
                          type="text"
                          value={ec.address}
                          onChange={(e) => {
                            handleEmergencyContactChange(e, index, "address");
                          }}
                          placeholder="Enter your address"
                          className="w-full px-4 py-3 outline-none border rounded-[8px] text-[#444454]"
                        />
                      </div>
                      <p></p>
                      <DeleteOutlined
                        onClick={() => {
                          const newEmergencyContacts = clientData.emergencyContacts.filter((_, i) => i !== index);
                          setClientData({ ...clientData, emergencyContacts: newEmergencyContacts });
                        }}
                        className="ml-auto text-red-500"
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col md:w-1/3 mr-4">
                <ImageUploader imageKeyFieldId="imageKey" modelId={clientData.id} modelType="clients" initialImageUrl={clientData.imageUrl} onImageUpload={handleImageUpload} autoUpload={true} />
              </div>
            </div>
            <div className="px-6 ">
              <button type="submit" className="bg-[#3795F7] px-6 py-3 text-white font-[500] rounded-[8px] mt-6">
                Save Client
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default EditClient;
