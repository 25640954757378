import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { handleSignIn, handleSignUp } from "../../redux/actions/auth";
import { useDispatch } from "react-redux";
import login from "../../assets/login.png";
// import styles from "./styles.module.css";

function Authentication() {
  // get current route
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname.slice(1);
  // const googleAuth = () => {
  //   window.open(`${process.env.REACT_APP_API_URL}/google/auth/google`, "_self");
  // };
  // // state variables
  // const [rememberState, setRememberState] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    name: "",
    email: "",
    password: "",
  });

  // // useEffect(() => {
  // //   localStorage.removeItem("accessToken");
  // //   localStorage.removeItem("refreshToken");
  // //   localStorage.removeItem("setupModalStatus");
  // // }, []);

  // handle Input
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };

  // handle submit
  const handleSubmit = async (e) => {
    try {
      // setLoading(true);
      e.preventDefault();
      if (currentRoute === "signup") {
        let res = await handleSignUp(loginData);
        if (res) {
          navigate("/signin");
        } else {
          dispatch({
            type: "CHANGE_ALERT_STATE",
            alerttype: "failed",
            heading: "Something went wrong!",
          });
        }
      } else {
        let res = await handleSignIn(loginData);
        if (res?.user?.role == "auditor") {
          navigate("/dashboard");
        } else if (res?.user?.role == "admin") {
          navigate("/dashboard");
        }
      }
    } finally {
      // setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen gap-16 ">
        <div className="w-1/2 h-full bg-[#3795F70D] flex items-center justify-center relative">
          <span className="p-8 text-2xl font-bold absolute top-0 left-0">Logo</span>
          <img src={login} alt="login" />
        </div>

        <div className="w-1/2">
          <div className="flex flex-col">
            <h1 className="text-[48px] font-semibold items-start">Welcome back</h1>
            <p className="text-[#444454]">
              Login to access your dashboard. Explore sessions, records, manage <br />
              clients & employees with ease.
            </p>
            <div className="mt-6 flex flex-col gap-2">
              <label className="text-[16px] text-[#444454] ">Email</label>
              <input type="email" name="email" onChange={handleInputs} className="w-2/3 p-3 outline-none border rounded-[8px] border-gray-300" placeholder="Enter your email" />
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <label className="text-[16px] text-[#444454] ">Password</label>
              <input className="w-2/3 p-3 outline-none border rounded-[8px] border-gray-300" type="password" name="password" onChange={handleInputs} placeholder="Enter your password" />
            </div>

            <div className="mt-6 flex items-center justify-between w-2/3">
              <div className="flex items-center gap-1 cursor-pointer">
                <input type="checkbox" className="outline-none border border-[#959595] " />
                <span className=" text-[14px] text-[#959595]">Remember me</span>
              </div>
              <span className="text-[#3795F7] text-[14px] font-semibold cursor-pointer">Forgot your password?</span>
            </div>

            <div className="mt-6 flex items-center justify-center w-2/3 bg-[#3795F7] cursor-pointer p-4 rounded-[8px]" onClick={handleSubmit}>
              <button className="text-white">Log In</button>
            </div>

            <div className="flex items-center justify-center w-2/3 gap-1 mt-8 cursor-pointer">
              <span className="text-[#959595]">Don't have an count?</span>
              <button className="text-[#3795F7] font-bold">Contact</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Authentication;
