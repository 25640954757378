import React from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import ReportData from "../../components/ReportData";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
const Report = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/generate-report");
  };
  return (
    <>
      <SearchBar />
      <div className="flex items-center gap-3 px-6 py-2 ">
        <div className="flex items-center gap-2 cursor-pointer text-sm text-[#696976]">
          <FaHome />
          <span className="cursor-pointer" onClick={() => navigate("/employee")}>
            Dashboard{" "}
          </span>
        </div>
        <span className="text-sm text-[#696976]">/ </span>

        <span className="cursor-pointer text-sm font-semibold text-[#444454]">Report/Audit</span>
      </div>

      <h1 className="px-6 py-2 text-[#161D20] font-bold text-[24px]">Report/Audit</h1>

      <div className="px-6 py-2">
        <div className="border rounded-lg ">
          <div className="p-4 px-6 border-b ">
            <div className="flex flex-wrap items-center justify-between gap-4 ">
              <h2 className="text-[20px] text-[#101828] font-[600]">All Client</h2>

              <button className="flex items-center text-sm font-[500] text-[#3795F7] gap-2 p-2 border border-[#3795F7] rounded-[8px]" onClick={handleClick}>
                <GoPlus className="text-xl" />
                <span className="">Generate New Report </span>
              </button>
            </div>
          </div>

          <div className="">
            <ReportData />
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
