import React, { useState, useEffect } from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import CalendarDates from "../../components/CalendarDates";
import ClientSideBar from "../../components/ClientSideBar";
import { Modal, Select, TimePicker, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getClientCalendar } from "../../redux/actions/clientsAction";
import { useNavigate, useParams } from "react-router-dom";
import { assignEmpToClient } from "../../redux/actions/employeesAction";
const Calendar = () => {
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const { uuid } = useParams();
  const [isRecurring, setIsRecurring] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients, selectedClient, selectedClientCalendar } = useSelector((state) => state.clients);
  const { employees } = useSelector((state) => state.employees);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [curDateTime, setCurDateTime] = useState({
    dateVal: "",
    date: new Date(),
    days: [],
    startTime: "",
    endTime: "",
    timeValue: null,
    isRecurring: true,
    type: "session",
    title: "",
    description: "",
  });
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = (e) => {
    // console.log(e);
    setOpen(false);
  };
  const handleCancel = (e) => {
    console.log(e);
    setOpen(false);
  };
  const saveAssignment = async () => {
    // console.log(modalData);
    let dt = [];
    dt.push(curDateTime);
    const res = await dispatch(
      await assignEmpToClient({
        employeeId: selectedEmployee,
        clientId: selectedClient.id,
        timeSheet: dt,
      })
    );
    await dispatch(await getClientCalendar(selectedClient.id));
    setOpen(false);
  };

  useEffect(() => {
    if (uuid && !selectedClient && clients.length > 0) {
      dispatch({
        type: "SET_SELECTED_CLIENT_UUID",
        payload: uuid,
      });
      return;
    }
    if (!selectedClient) return;
    if (selectedClient && selectedClient.id) {
      dispatch(getClientCalendar(selectedClient.id));
    }
  }, [selectedClient, clients]);

  return (
    selectedClient && (
      <>
        {/* <SearchBar /> */}
        <div className="flex items-center gap-3 px-6 pt-6 ">
          <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
            <FaHome />
            <span className="cursor-pointer">Dashboard </span>
          </div>
          <span className="text-[16px] text-[#696976]">/ </span>

          <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Client</span>
          <span className="text-[16px] text-[#696976]">/ </span>

          <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Calendars</span>
        </div>
        {selectedClient && (
          <div className={`flex items-center justify-between  w-full py-3 px-12 `}>
            <div className="flex items-center gap-2">
              <div className="aspect-w-1 aspect-h-1 overflow-hidden w-16 h-16 rounded-full">
                <img src={selectedClient.imageUrl ? selectedClient.imageUrl : "/images/user.png"} alt="employee" className="object-cover w-full h-full" />
              </div>
              <div>
                <h1 className="text-[#161D20] text-[1.5rem] -mb-1 font-bold">{selectedClient.fullName}</h1>
                <span className="text-[#444454] text-[1rem]">{selectedClient.uci}</span>
                {selectedClient?.email && <span className="text-[#444454] text-[1rem]"> - {selectedClient?.email && selectedClient.email}</span>}
              </div>
            </div>
            {user?.role == "admin" && (
              <button onClick={showModal} className="flex items-center text-[16px] font-[500] text-[#3795F7] gap-2 px-2 py-1 border border-[#3795F7] rounded-[8px] ">
                <GoPlus className="text-xl" />
                <span className="">Add Event</span>
              </button>
            )}
          </div>
        )}
        <div className="grid grid-cols-10 gap-1 px-1 h-[80vh]">
          <ClientSideBar tab={"Calendar / MAR"} uuid={selectedClient.uuid} />
          <div className="col-span-8 px-2 h-[80vh] overflow-y-scroll">
            <div className="border rounded-lg ">
              {/* <div className="p-6 border-b ">
            <div className="flex items-center justify-between ">
              <h2 className="text-[20px] text-[#101828] font-[600]">
                {" "}
                {selectedClient ? (
                  <div className="flex items-center">
                    {selectedClient.fullName} <span className="text-gray-500 ml-3 text-sm"> {selectedClient.email}</span>
                  </div>
                ) : (
                  "Event Calendars"
                )}
              </h2>
              {user?.role == "admin" && (
                <button onClick={showModal} className="flex items-center text-[16px] font-[500] text-[#3795F7] gap-2 px-2 py-1 border border-[#3795F7] rounded-[8px]">
                  <GoPlus className="text-xl" />
                  <span className="">Add Event</span>
                </button>
              )}
            </div>
          </div> */}

              <div className="flex">
                {/* <div className="flex flex-col w-[260px] p-4 gap-4 border-r">
              <span className="text-[18px] text-[#959595]">CALENDARS</span>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">All View</span>
              </div>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">Employee</span>
              </div>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">Client</span>
              </div>
            </div> */}

                <div className="w-full p-4">
                  <CalendarDates events={selectedClientCalendar} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Add Sessions"
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            hidden: true,
          }}
          cancelButtonProps={{
            hidden: true,
          }}>
          {/* Mode tab to select one time or recurring mode */}
          <div className="flex flex-row w-full gap-4 mb-2">
            <button
              className={`${curDateTime.type == "mar" ? "bg-[#3795F7] text-white" : "bg-[#E5E7EB]"}  w-full px-6 py-2 rounded-[8px] `}
              onClick={() => setCurDateTime({ ...curDateTime, type: "mar" })}>
              MAR
            </button>
            <button
              className={`${curDateTime.type == "session" ? "bg-[#3795F7] text-white" : "bg-[#E5E7EB]"} w-full px-6 py-2  rounded-[8px] `}
              onClick={() => setCurDateTime({ ...curDateTime, type: "session" })}>
              Session
            </button>
          </div>

          {curDateTime.type == "mar" && (
            <>
              <div className="relative flex flex-col w-full gap-2">
                <label className="text-[16px] text-[#444454] mt-4">Title</label>
                <div className="relative flex w-full ">
                  <input
                    type="text"
                    className="w-full h-10 border rounded-[8px] outline-none px-4"
                    value={curDateTime?.title}
                    onChange={(e) =>
                      setCurDateTime({
                        ...curDateTime,
                        title: e.target.value,
                      })
                    }
                    placeholder="Enter a title..."
                  />
                </div>
              </div>
              <div className="relative flex flex-col w-full gap-2">
                <label className="text-[16px] text-[#444454] mt-2">Description</label>
                <div className="relative flex w-full ">
                  <textarea
                    type="text"
                    rows={3}
                    className="w-full border rounded-[8px] outline-none px-4"
                    value={curDateTime?.description}
                    onChange={(e) =>
                      setCurDateTime({
                        ...curDateTime,
                        description: e.target.value,
                      })
                    }
                    placeholder="Enter description..."
                  />
                </div>
              </div>
            </>
          )}
          {curDateTime.type == "session" && (
            <div className="relative flex flex-col w-full gap-2">
              <label className="text-[16px] text-[#444454] mt-5">Search Employee</label>
              <div className="relative flex w-full ">
                <Select
                  showSearch
                  placeholder="Select an employee"
                  value={selectedEmployee}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.label.props.children[0].toLowerCase().includes(input.toLowerCase())}
                  className="w-full h-10"
                  onChange={(e) => setSelectedEmployee(e)}
                  options={employees.map((employee) => ({
                    value: employee.id,
                    label: (
                      <>
                        {employee.fullName} <span className="text-gray-500 text-xs">{employee.email}</span>
                      </>
                    ),
                  }))}
                />
              </div>
            </div>
          )}
          <div className="flex flex-row w-full gap-4 mt-8">
            <button
              className={`${!curDateTime.isRecurring ? "bg-[#3795F7] text-white" : "bg-[#E5E7EB]"} w-full px-6 py-2  rounded-[8px] `}
              onClick={() => setCurDateTime({ ...curDateTime, isRecurring: false })}>
              One Time
            </button>
            <button
              className={`${curDateTime.isRecurring ? "bg-[#3795F7] text-white" : "bg-[#E5E7EB]"}  w-full px-6 py-2 rounded-[8px] `}
              onClick={() => setCurDateTime({ ...curDateTime, isRecurring: true })}>
              Recurring
            </button>
          </div>
          <div className="flex flex-row w-full gap-4 mt-4">
            {curDateTime.isRecurring ? (
              <Select
                mode="tags"
                className="w-full cursor-pointer text-[#959595] bg-[#F9FAFB] border rounded-[8px] outline-none"
                placeholder="Please select days"
                value={curDateTime.days}
                onChange={(e) => setCurDateTime({ ...curDateTime, days: e })}
                options={[{ value: "monday" }, { value: "tuesday" }, { value: "wednesday" }, { value: "thursday" }, { value: "friday" }, { value: "saturday" }, { value: "sunday" }]}
              />
            ) : (
              <DatePicker
                value={curDateTime.dateVal}
                className="w-full p-2 cursor-pointer text-[#959595] bg-[#fff] border rounded-[8px] outline-none"
                onChange={(e) => setCurDateTime({ ...curDateTime, date: e.$d, dateVal: e })}
              />
            )}
            <TimePicker.RangePicker
              className="w-full border cursor-pointer text-[#959595] bg-[#fff] p-2 rounded-[8px] outline-none"
              use12Hours
              format="h:mm a"
              value={curDateTime.timeValue}
              minuteStep={10}
              onChange={(e) => setCurDateTime({ ...curDateTime, startTime: e[0].$d, endTime: e[1].$d, timeValue: e })}
            />
          </div>
          <div className="flex flex-row w-full gap-6 mt-10">
            <button className="bg-[#E5E7EB] w-full px-0 py-2 text-black rounded-[8px] " onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button type="submit" onClick={saveAssignment} className="bg-[#3795F7] w-full px-0 py-2 text-white rounded-[8px] ">
              Add
            </button>
          </div>
        </Modal>
      </>
    )
  );
};

export default Calendar;
