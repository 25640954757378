import React from "react";
import logo from "../assets/Avatar.png";
import { IoSearch } from "react-icons/io5";
import { RiTranslate2 } from "react-icons/ri";
import { AiOutlineAppstore } from "react-icons/ai";
import { RiMoonLine } from "react-icons/ri";
import { IoNotificationsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
const SearchBar = () => {
  const user = useSelector((state) => state.auth.user);
  // console.log(user)
  return (
    <div className="py-4 pl-4">
      <div className="px-4 w-full border border-gray-100 shadow-md rounded-[10px] ">
        <div className="w-full p-1 flex items-center justify-between">
          <div className="flex items-center w-full gap-4">
            <IoSearch className="text-xl" />
            <input type="search" placeholder="Search (Ctrl+/)" className="p-2 outline-none " />
          </div>

          {/* <div className="flex cursor-pointer flex-row items-center justify-center gap-4 text-[22px] text-[#444454] font-semibold">
              <RiTranslate2 className="" />
              <AiOutlineAppstore />
              <RiMoonLine />
              <IoNotificationsOutline />
              <img src={logo} alt="log" />
            </div> */}
          <div className="flex cursor-pointer items-center justify-center gap-4 text-2xl text-[#444454] font-semibold">
            {/* <RiTranslate2 className="hidden md:inline-block" />
            <AiOutlineAppstore className="hidden md:inline-block" />
            <RiMoonLine className="hidden md:inline-block" />
            <IoNotificationsOutline className="hidden md:inline-block" /> */}
            <img src={logo} alt="log" className="w-8 h-8 rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
