import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "../../redux/actions/AxiosInstance/unAuthenticated";
import { useDispatch } from "react-redux";
const VerifyGoogleAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      token: searchParams.get("token"),
    };
  };

  const verifyGoogleAuth = async () => {
    const { token } = getQueryParams();
    const { data, status } = await instance.post("/user/refresh-token", {
      refreshToken: token,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      navigate("/home");
    }

    console.log(data);
  };

  useEffect(() => {
    verifyGoogleAuth();
  }, [location]);

  return <div>VerifyGoogleAuth</div>;
};

export default VerifyGoogleAuth;
