import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import ClientSideBar from "../../components/ClientSideBar";
import { FaHome } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import authAxios from "../../redux/actions/AxiosInstance/authenticated";
import View from "./View";
import EditView from "./Edit";
import AddSummary from "./Add";
const QuarterlySummary = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { selectedClient, clients } = useSelector((state) => state.clients);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    // console.log(selectedClient);
    if (uuid && !selectedClient && clients.length > 0) {
      dispatch({
        type: "SET_SELECTED_CLIENT_UUID",
        payload: uuid,
      });
      return;
    }
    if (!selectedClient) return;
  }, [selectedClient, clients]);

  const [selectedIndexes, setSelectedIndexes] = useState({ employee: null, summary: null });
  const [currentSummary, setCurrentSummary] = useState(null);
  const [mode, setMode] = useState("view");
  const [allSummaries, setAllSummaries] = useState([]);
  const [empLoading, setEmpLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const handleSelectEmp = async (id) => {
    setEmpLoading(true);
    setCurrentSummary(null);
    setSelectedIndexes({ employee: null, summary: null });
    // call api
    const resdata = await authAxios.post(`/clients/get-all-quarterly-summary`, {
      clientId: id,
    });
    setAllSummaries(resdata.data.summaries);
    if (resdata.data.summaries.length > 0) {
      setCurrentSummary(resdata.data.summaries[0]);
      setSelectedIndexes({ ...selectedIndexes, summary: 0 });
    }
    setEmpLoading(false);
    // console.log(resdata.data.Summaries);
  };

  useEffect(() => {
    if (selectedClient && selectedClient?.id) {
      handleSelectEmp(selectedClient.id);
    }
  }, [selectedClient]);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  return (
    selectedClient && (
      <div className="overflow-hidden">
        <div className="flex items-center gap-3 px-6 pt-6 ">
          <div className="flex items-center gap-2 cursor-pointer text-[16px] font-[400] text-[#696976]">
            <FaHome />
            <span className="cursor-pointer">Dashboard </span>
          </div>
          <span className="text-[16px] text-[#696976]">/ </span>
          <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">Client</span>

          <span className="text-[16px] text-[#696976]">/ </span>

          <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">Summary</span>
        </div>
        {selectedClient && (
          <div className={`flex items-center justify-between mx-8 w-1/2 py-3 px-4 `}>
            <div className="flex items-center gap-2  w-full">
              <div className="aspect-w-1 aspect-h-1 overflow-hidden  h-16 w-16 rounded-full">
                <img src={selectedClient.imageUrl ? selectedClient.imageUrl : "/images/user.png"} alt="employee" className="object-cover w-full h-full" />
              </div>
              <div>
                <h1 className="text-[#161D20] text-[1.5rem] -mb-1 font-bold">{selectedClient.fullName}</h1>
                <span className="text-[#444454] text-[1rem]">{selectedClient.uci}</span>
                {selectedClient?.email && <span className="text-[#444454] text-[1rem] "> - {selectedClient?.email && selectedClient.email}</span>}
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-10 gap-1 px-1 h-[85vh] overflow-hidden">
          <ClientSideBar tab={"Quarterly Summary"} uuid={selectedClient.uuid} />
          {/* left side */}
          <div className="col-span-2 gap-4 rounded-[8px] border shadow-md h-[80vh] overflow-scroll">
            {/* <div className="border shadow-md rounded-[8px] "> */}
            {allSummaries.map((item, index) => (
              <div
                key={index}
                onClick={async () => {
                  setCurrentSummary(null);
                  setSummaryLoading(true);
                  // sleep for 1 sec
                  await new Promise((resolve) => setTimeout(resolve, 250));

                  setSelectedIndexes({
                    ...selectedIndexes,
                    summary: index,
                  });

                  setSummaryLoading(false);
                  setCurrentSummary(item);
                  setMode("view");
                }}
                className={`flex items-center justify-between py-3 w-full text-center border shadow-sm hover:shadow-md cursor-pointer ${index == 0 && "rounded-t-[8px]"} ${
                  selectedIndexes.summary === index && "bg-gray-200"
                }`}>
                <div className="flex items-center text-center w-full">
                  {/* {item.id}
                  <img src={item?.client?.imageUrl ? item?.client?.imageUrl : "/images/user.png"} alt="employee" className="w-10 h-10 rounded-full" /> */}
                  <div className="text-center w-full">
                    <p className="text-sm">{item?.quarterName}</p>
                  </div>
                </div>
              </div>
            ))}

            {user.role == "admin" && (
              <div
                onClick={async () => {
                  setCurrentSummary(null);
                  setSummaryLoading(true);
                  // sleep for 1 sec
                  await new Promise((resolve) => setTimeout(resolve, 250));

                  setSelectedIndexes({
                    ...selectedIndexes,
                    summary: allSummaries.length,
                  });

                  setSummaryLoading(false);
                  setCurrentSummary(null);
                  setMode("add");
                }}
                className={`flex items-center justify-between py-3 w-full text-center border shadow-sm hover:shadow-md cursor-pointer ${allSummaries.length == 0 && "rounded-t-[8px]"} ${
                  selectedIndexes.summary === allSummaries.length && "bg-gray-200"
                }`}>
                <div className="flex items-center text-center w-full" onClick={() => setMode("add")}>
                  {/* {item.id}
                  <img src={item?.client?.imageUrl ? item?.client?.imageUrl : "/images/user.png"} alt="employee" className="w-10 h-10 rounded-full" /> */}
                  <div className="text-center w-full">
                    <p className="text-sm">+ Add New</p>
                  </div>
                </div>
              </div>
            )}
            {empLoading && <Loader className="mt-40" />}

            {!empLoading && allSummaries.length == 0 && (
              <div className="text-center w-full my-auto mt-12">
                <p className="text-sm text-gray-700">No Summary Found</p>
              </div>
            )}
          </div>
          {/* right side */}
          {summaryLoading && <Loader className="mt-40" />}
          {currentSummary && mode == "edit" && (
            <EditView
              currentSummary={currentSummary}
              setCurrentSummary={setCurrentSummary}
              selectedClient={selectedClient}
              allSummaries={allSummaries}
              setAllSummaries={setAllSummaries}
              setMode={setMode}
            />
          )}
          {currentSummary && mode == "view" && <View currentSummary={currentSummary} setCurrentSummary={setCurrentSummary} selectedClient={selectedClient} setMode={setMode} />}
          {mode == "add" && (
            <AddSummary
              currentSummary={currentSummary}
              setCurrentSummary={setCurrentSummary}
              selectedClient={selectedClient}
              allSummaries={allSummaries}
              setAllSummaries={setAllSummaries}
              setMode={setMode}
              setSelectedIndexes={setSelectedIndexes}
            />
          )}
        </div>
      </div>
    )
  );
};

export default QuarterlySummary;
