import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import CalendarDates from "../../components/CalendarDates";
import { Modal, Select, TimePicker, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getEmpCalendar, assignEmpToClient } from "../../redux/actions/employeesAction";
import { getClientCalendar } from "../../redux/actions/clientsAction";
import { useNavigate } from "react-router-dom";
const Calendar = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employees, selectedEmployee, selectedEmpCalendar } = useSelector((state) => state.employees);
  const user = useSelector((state) => state.auth.user);
  const { clients } = useSelector((state) => state.clients);
  const [selectedClient, setSelectedClient] = useState();
  const [allAssignedClients, setAllAssignedClients] = useState([]);
  const [curDateTime, setCurDateTime] = useState({ dateVal: "", type: "session", date: new Date(), days: [], startTime: "", endTime: "", timeValue: null, isRecurring: true });

  const saveAssignment = async () => {
    // console.log(modalData);
    let dt = [];
    dt.push(curDateTime);
    const res = await dispatch(
      await assignEmpToClient({
        employeeId: selectedEmployee.id,
        clientId: selectedClient,
        timeSheet: dt,
      })
    );
    const a_clients = await dispatch(await getEmpCalendar(selectedEmployee.id));
    setOpen(false);
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = (e) => {
    // console.log(e);
    setOpen(false);
  };
  const handleCancel = (e) => {
    // console.log(e);
    setOpen(false);
  };
  useEffect(() => {
    if (selectedEmployee && selectedEmployee.id) {
      dispatch(getEmpCalendar(selectedEmployee.id));
    }
  }, [selectedEmployee]);

  return (
    <>
      <SearchBar />
      <div className="flex items-center gap-3 px-6 py-4 ">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard </span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Employee</span>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Calendar</span>
      </div>

      <div className="px-6 py-2">
        <div className="border rounded-lg ">
          <div className="p-6 border-b ">
            <div className="flex items-center justify-between ">
              <h2 className="text-[20px] text-[#101828] font-[600]">
                {selectedEmployee ? (
                  <div className="flex items-center">
                    {selectedEmployee.fullName} <span className="text-gray-500 ml-3 text-sm"> {selectedEmployee.email}</span>
                  </div>
                ) : (
                  "Event Calendars"
                )}
              </h2>
              {user?.role == "admin" && (
                <button onClick={showModal} className="flex items-center text-[16px] font-[500] text-[#3795F7] gap-2 px-2 py-1 border border-[#3795F7] rounded-[8px]">
                  <GoPlus className="text-xl" />
                  <span className="">Add Event</span>
                </button>
              )}
            </div>
          </div>

          <div className="flex">
            {/* <div className="flex flex-col w-[260px] p-4 gap-4 border-r">
              <span className="text-[18px] text-[#959595]">CALENDARS</span>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">All View</span>
              </div>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">Employee</span>
              </div>
              <div className="flex flex-row gap-2">
                <input type="checkbox" />
                <span className="text-[16px] text-[#959595]">Client</span>
              </div>
            </div> */}

            <div className="w-full p-4">
              <CalendarDates events={selectedEmpCalendar} />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Add Sessions"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          hidden: true,
        }}
        cancelButtonProps={{
          hidden: true,
        }}>
        {/* Mode tab to select one time or recurring mode */}
        <div className="flex flex-row w-full gap-4">
          <button
            className={`${!curDateTime.isRecurring ? "bg-[#3795F7] text-white" : "bg-[#E5E7EB]"} w-full px-6 py-2  rounded-[8px] `}
            onClick={() => setCurDateTime({ ...curDateTime, isRecurring: false })}>
            One Time
          </button>
          <button
            className={`${curDateTime.isRecurring ? "bg-[#3795F7] text-white" : "bg-[#E5E7EB]"}  w-full px-6 py-2 rounded-[8px] `}
            onClick={() => setCurDateTime({ ...curDateTime, isRecurring: true })}>
            Recurring
          </button>
        </div>
        <div className="relative flex flex-col w-full gap-2">
          <label className="text-[16px] text-[#444454] mt-5">Search Client</label>
          <div className="relative flex w-full ">
            <Select
              showSearch
              placeholder="Select an Client"
              value={selectedClient}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.label.props.children[0].toLowerCase().includes(input.toLowerCase())}
              className="w-full h-10"
              onChange={(e) => setSelectedClient(e)}
              options={clients.map((employee) => ({
                value: employee.id,
                label: (
                  <>
                    {employee.fullName} <span className="text-gray-500 text-xs">{employee.email}</span>
                  </>
                ),
              }))}
            />
          </div>
        </div>

        <div className="flex flex-row w-full gap-4 mt-4">
          {curDateTime.isRecurring ? (
            <Select
              mode="tags"
              className="w-full cursor-pointer text-[#959595] bg-[#F9FAFB] border rounded-[8px] outline-none"
              placeholder="Please select days"
              value={curDateTime.days}
              onChange={(e) => setCurDateTime({ ...curDateTime, days: e })}
              options={[{ value: "monday" }, { value: "tuesday" }, { value: "wednesday" }, { value: "thursday" }, { value: "friday" }, { value: "saturday" }, { value: "sunday" }]}
            />
          ) : (
            <DatePicker
              value={curDateTime.dateVal}
              className="w-full p-2 cursor-pointer text-[#959595] bg-[#fff] border rounded-[8px] outline-none"
              onChange={(e) => setCurDateTime({ ...curDateTime, date: e.$d, dateVal: e })}
            />
          )}
          <TimePicker.RangePicker
            className="w-full border cursor-pointer text-[#959595] bg-[#fff] p-2 rounded-[8px] outline-none"
            use12Hours
            format="h:mm a"
            value={curDateTime.timeValue}
            minuteStep={10}
            onChange={(e) => setCurDateTime({ ...curDateTime, startTime: e[0].$d, endTime: e[1].$d, timeValue: e })}
          />
        </div>
        <div className="flex flex-row w-full gap-6 mt-10">
          <button className="bg-[#E5E7EB] w-full px-0 py-2 text-black rounded-[8px] " onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button type="submit" onClick={saveAssignment} className="bg-[#3795F7] w-full px-0 py-2 text-white rounded-[8px] ">
            Add
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Calendar;
