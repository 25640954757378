import React, { useState, useEffect } from "react";
import { DatePicker, Input, Button, Select } from "antd";
import moment from "moment";
import { CiTrash } from "react-icons/ci";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { updateASummary } from "../../redux/actions/clientsAction";
const EditSummary = ({ currentSummary, setCurrentSummary, allSummaries, setAllSummaries, setMode, selectedClient }) => {
  const [summaryData, setSummaryData] = useState(currentSummary);
  const { clients } = useSelector((state) => state.clients);
  const { employees } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  useEffect(() => {
    setSummaryData(currentSummary);
  }, [currentSummary]);

  const handleChange = (e) => {
    // console.log(e)
    const { name, value } = e.target;
    setSummaryData({ ...summaryData, [name]: value });
  };

  const handleDateChange = (e, key) => {
    const newDate = moment(e.target.value);
    setSummaryData({ ...summaryData, [key]: newDate.toISOString() });
  };

  const handleGoalsChange = (index, value) => {
    const updatedGoals = [...summaryData.goals];
    updatedGoals[index] = value;
    setSummaryData({ ...summaryData, goals: updatedGoals });
  };

  const handleAddGoal = () => {
    const updatedGoals = [...summaryData.goals, ""];
    setSummaryData({ ...summaryData, goals: updatedGoals });
  };

  const handleRemoveGoal = (index) => {
    const updatedGoals = summaryData.goals.filter((_, i) => i !== index);
    setSummaryData({ ...summaryData, goals: updatedGoals });
  };

  const handleSummariesChange = (index, value) => {
    const updatedSummaries = [...summaryData.summary];
    updatedSummaries[index] = value;
    setSummaryData({ ...summaryData, summary: updatedSummaries });
  };

  const handleAddSummary = () => {
    const updatedSummaries = [...summaryData.summary, ""];
    setSummaryData({ ...summaryData, summary: updatedSummaries });
  };

  const handleRemoveSummary = (index) => {
    const updatedSummaries = summaryData.summary.filter((_, i) => i !== index);
    setSummaryData({ ...summaryData, summary: updatedSummaries });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setCurrentSummary(summaryData);
    // update-summary
    const summary = await dispatch(await updateASummary(summaryData));
    if (summary) {
      setCurrentSummary(summary);
      setAllSummaries(allSummaries.map((item) => (item.id === summary.id ? summary : item)));
      setMode("view");
    }
  };
  const years = Array.from({ length: 2035 - 2000 + 1 }, (v, k) => k + 2000);

  return (
    <div className="col-span-6 p-4 rounded-[8px] border shadow-md h-[80vh] overflow-y-scroll">
      <div className="flex flex-row justify-between">
        {currentSummary && (
          <p className="text-sm text-gray-700">
            Summary ID: <span className="font-bold">{currentSummary.id}</span>
          </p>
        )}
        <p></p>
      </div>
      <div className="w-full p-2 mt-3 ">
        {/* name */}
        <div className="flex items-center justify-between">
          <h1 className="text-[24px] font-bold text-[#161D20]">{selectedClient.fullName}</h1>
          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
        </div>

        {/* time & date */}
        <div className="flex flex-row gap-4 mt-0">
          <span className="text-[16px]  text-[#444454CC]">{selectedClient.uci}</span>
          {selectedClient?.email && <p className="text-[#444454] text-[1rem]">{selectedClient?.email && selectedClient.email}</p>}
        </div>
        {/* role */}
      </div>
      <form onSubmit={handleSave}>
        {/* <div className="mt-3 flex gap-2 items-center justify-start">
          <label>Quarter: </label>
          <Select
            style={{
              width: "15%",
            }}
            value={summaryData.quarterName.split(" ")[0]}
            placeholder="Quarter"
            onChange={(e) => {
              setSummaryData({ ...summaryData, employeesId: e });
            }}
            options={[
              { label: "Q1", value: "Q1" },
              { label: "Q2", value: "Q2" },
              { label: "Q3", value: "Q3" },
              { label: "Q4", value: "Q4" },
            ]}
          />
          <Select
            style={{ width: "20%" }}
            placeholder="Select a year"
            onChange={(val) => {
              setSummaryData({ ...summaryData, quarterName: `${summaryData.quarterName.split(" ")[0]} ${val}` });
            }}
            value={summaryData.quarterName.split(" ")[1]}
            options={years.map((year) => ({
              label: year,
              value: year,
            }))}
          />
        </div> */}
        <div className="mt-6 flex gap-2 items-center justify-start">
          <label>Employees: </label>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "85%",
            }}
            value={summaryData.employeesId}
            placeholder="Please select the staffs"
            onChange={(e) => {
              setSummaryData({ ...summaryData, employeesId: e });
            }}
            options={employees.map((employee) => ({
              label: employee.fullName,
              value: employee.id,
            }))}
          />
        </div>

        <div className="mt-6 flex items-center gap-4">
          <label htmlFor="startDate">Quarter Title: </label>
          <input
            type="text"
            id="startDate"
            value={currentSummary.quarterName}
            onChange={(e) =>
              setSummaryData({
                ...summaryData,
                quarterName: e.target.value,
              })
            }
            className="border py-1.5 rounded-lg text-sm px-3"
          />
        </div>
        <div className="mt-6 flex items-center gap-4">
          <label htmlFor="startDate">Starting Date: </label>
          <input
            type="date"
            id="startDate"
            value={moment(summaryData.fromDate).format("YYYY-MM-DD")}
            onChange={(e) => handleDateChange(e, "fromDate")}
            className="border py-1.5 rounded-lg text-sm px-3"
          />
        </div>
        <div className="mt-6 flex items-center gap-4">
          <label htmlFor="endDate">Ending Date: </label>
          <input type="date" id="endDate" value={moment(summaryData.toDate).format("YYYY-MM-DD")} onChange={(e) => handleDateChange(e, "toDate")} className="border py-1.5 rounded-lg text-sm px-3" />
        </div>
        <div className="mt-4 ">
          <div className="flex mb-3 justify-between items-center">
            <h3>Goals</h3>
            <Button onClick={handleAddGoal}>+ Add More</Button>
          </div>

          {summaryData.goals.map((goal, index) => (
            <div key={index} className="flex mb-1">
              <Input value={goal} onChange={(e) => handleGoalsChange(index, e.target.value)} style={{ width: "calc(100% - 60px)", display: "inline-block" }} />
              <button onClick={() => handleRemoveGoal(index)} style={{ marginLeft: "10px" }}>
                <CiTrash />
              </button>
            </div>
          ))}
        </div>
        <div className="mt-6 ">
          <div className="flex mb-3 justify-between items-center">
            <h3>Summaries</h3>
            <Button onClick={handleAddSummary}>+ Add More</Button>
          </div>
          {summaryData.summary.map((item, index) => (
            <div key={index} className="flex mb-1">
              <Input value={item} onChange={(e) => handleSummariesChange(index, e.target.value)} style={{ width: "calc(100% - 60px)", display: "inline-block" }} />
              <button onClick={() => handleRemoveSummary(index)} style={{ marginLeft: "10px" }}>
                <CiTrash />
              </button>
            </div>
          ))}
        </div>
        <div className="flex gap-4 items-center mt-4">
          <button type="button" className="text-sm" onClick={() => setMode("view")}>
            Cancel
          </button>
          <Button type="primary" htmlType="submit" className="mt-3 mb-5">
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditSummary;
