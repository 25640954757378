import { Outlet, Navigate } from "react-router-dom";
import { isExpired } from "react-jwt";
const PrivateRoutes = () => {
  let auth = localStorage.getItem("accessToken");
  console.log(auth);
  const isMyTokenExpired = isExpired(auth);
  console.log(isMyTokenExpired);
  if (isMyTokenExpired) localStorage.clear();
  return !isMyTokenExpired ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoutes;
