import React, { useState } from "react";
import { Table, Input, Button } from "antd";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { RiDeleteBinLine } from "react-icons/ri";
import { PiEye } from "react-icons/pi";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const ReportData = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "number",
    },
    {
      title: "Status",
      dataIndex: "status",

      render: (status) => (
        <span>
          {status === "processing" ? (
            <div className="bg-[#F9F5FF] cursor-pointer py-2 rounded-[9px] flex items-center   justify-center">
              <span className="text-[16px] text-[#6941C6] font-[500]">Processing</span>
            </div>
          ) : status === "ready" ? (
            <div className="bg-[#EFF8FF]  cursor-pointer py-2 rounded-[9px] flex items-center   justify-center">
              <span className="text-[16px] text-[#3795F7] font-[500]">Ready</span>
            </div>
          ) : status === "send" ? (
            <div className="bg-[#ECFDF3]  cursor-pointer py-2 rounded-[9px] flex items-center   justify-center">
              <span className="text-[16px] text-[#027A48] font-[500]">Send</span>
            </div>
          ) : (
            ""
          )}
        </span>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      width: 1,
      render: (_, record) => (
        <div className="flex items-center justify-start gap-6 text-[#444454] text-[24px]">
          <RiDeleteBinLine className="cursor-pointer" onClick={() => handleCalendar(record)} />
          <PiEye className="cursor-pointer" onClick={() => handleEdit(record)} />
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    navigate("/edit-client");
    console.log("Edit clicked for record:", record);
  };

  const handleCalendar = (record) => {
    console.log("Calendar clicked for record:", record);
  };

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      id: i + 1,
      name: `Edward King ${i}`,
      email: "email@example.com",
      number: "+88 0252659566",
      status: "processing",
      action: "", // Leave it empty for now, it will be populated in the render function
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSearch = (value) => {
    setSearchText(value);
  };
  const filteredData = data.filter((item) => {
    const lowerSearchText = searchText.toLowerCase();
    return (
      (item.name && item.name.toLowerCase().includes(lowerSearchText)) ||
      (item.email && item.email.toLowerCase().includes(lowerSearchText)) ||
      (item.type && item.type.toLowerCase().includes(lowerSearchText)) ||
      (item.number && item.number.includes(searchText))
    );
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = changeableRowKeys.filter((_, index) => index % 2 !== 0);
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = changeableRowKeys.filter((_, index) => index % 2 === 0);
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const paginationConfig = {
    showSizeChanger: false,
    position: ["bottomCenter"],
    prevIcon: (
      <Button type="text" size="large" className="flex items-center justify-center gap-2 w-[115px] text-[#444454] border-gray-200 border rounded-[8px] absolute bottom-0 left-0 ml-4 ">
        <FaArrowLeft />
        <span className="text-[14px]">Previous</span>
      </Button>
    ),
    nextIcon: (
      <Button type="text" size="large" className="flex items-center justify-center gap-2 w-[115px] text-[#444454] border border-gray-200 rounded-[8px] absolute bottom-0 right-0 mr-4 ">
        <span className="text-[14px]">Next</span>
        <FaArrowRight />
      </Button>
    ),
  };

  return (
    <div>
      <div className="relative flex flex-col w-full gap-2 p-4 md:w-1/2">
        <div className="relative flex w-full ">
          <IoSearch className="text-[18px] text-[#696976] absolute left-2 top-3" />
          <input
            className="pl-8 rounded-[8px] w-full p-2 border outline-none"
            placeholder="Search Employee"
            value={searchText} // Controlled input value
            onChange={(e) => onSearch(e.target.value)} // Handle input change
          />
        </div>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={filteredData} style={{ color: "#444454" }} pagination={paginationConfig} />
    </div>
  );
};

export default ReportData;
