import { combineReducers } from "redux";

import auth from "./authReducer";
import clients from "./clientsReducer";
import employees from "./employeesReducer";
import sessions from "./sessionsReducer";

export default combineReducers({
  auth: auth,
  clients: clients,
  employees: employees,
  sessions: sessions,
});
