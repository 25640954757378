import React from "react";
import { CiEdit } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
const View = ({ currentSummary, setCurrentSummary, selectedClient, setMode }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className=" col-span-6 p-4 rounded-[8px] border shadow-md h-[80vh] overflow-y-scroll">
      <div className="flex items-center justify-end px-2 pb-2  ">
        <p className="text-[#444454] text-sm"></p>
        {user.role == "admin" && <CiEdit className="cursor-pointer text-lg" onClick={() => setMode("edit")} />}
      </div>
      <div className="rounded-[8px] mb-16 h-screen ">
        <div className="flex justify-between">
          <h2 className="pb-2 text-sm text-gray-500">
            Quarter: <span className="font-bold">{currentSummary?.quarterName}</span>
          </h2>
          <h2 className="pb-2 text-sm text-gray-500">ID: {currentSummary?.id}</h2>
        </div>

        {/* employee details */}
        <div className="w-full p-2 mt-3 ">
          {/* name */}
          <div className="flex items-center justify-between">
            <h1 className="text-[24px] font-bold text-[#161D20]">{currentSummary?.client?.fullName}</h1>
            {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
          </div>

          {/* time & date */}
          <div className="flex flex-row gap-4 mt-0">
            <span className="text-[16px]  text-[#444454CC]">{currentSummary?.client?.uci}</span>
            {currentSummary?.client?.email && <span className="text-[16px]  text-[#444454CC]">{currentSummary.client.email}</span>}
          </div>
          {/* role */}
        </div>

        {/* Audio Sharing */}
        <div className="w-full p-2 mt-3 ">
          {/* name */}
          <div className="flex items-center justify-between">
            <h1 className="text-[24px] font-bold text-[#161D20]">Months Covered</h1>
            {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
          </div>
          {/* time  */}
          {/* fromDate DateTime @default(now()) 
          toDate DateTime @default(now()) */}
          <div className="flex flex-col mt-1">
            <div className="flex flex-row gap-10">
              <span className="text-[16px]  text-[#444454CC]">Starting Date :</span>
              <span className="text-[16px]  text-[#444454CC]">{new Date(currentSummary.fromDate).toDateString()}</span>
            </div>
            <div className="flex flex-row gap-10">
              <span className="text-[16px]  text-[#444454CC]">Ending Date&nbsp;:</span>
              <span className="text-[16px]  text-[#444454CC]">
                &nbsp;&nbsp;
                {new Date(currentSummary.toDate).toDateString()}
              </span>
            </div>
          </div>
        </div>

        {currentSummary?.employees && currentSummary.employees.length > 0 && (
          <div className="w-full p-2 mt-3 ">
            {/* heading */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">Staffs</h1>
              {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
            </div>
            <ul className=" w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
              {currentSummary.employees?.map((item, index) => (
                <li itemType="" className="text-base list-item text-[#3c3c43cc]" key={index}>
                  {item.fullName} - {item.email}
                </li>
              ))}
            </ul>
          </div>
        )}
        {currentSummary?.goals && currentSummary.goals.length > 0 && (
          <div className="w-full p-2 mt-3 ">
            {/* heading */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">Goals</h1>
              {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
            </div>
            <ul className=" w-full gap-1 px-1 ml-4" style={{ listStyleType: "none" }}>
              {currentSummary.goals?.map((item, index) => (
                <li itemType="" className="text-base list-item text-[#303031cc] mb-1" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        {currentSummary?.summary && currentSummary.summary.length > 0 && (
          <div className="w-full p-2 mt-3 ">
            {/* heading */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">Quarterly Summaries</h1>
              {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
            </div>
            <ul className=" w-full gap-1 px-1 ml-4" style={{ listStyleType: "none" }}>
              {currentSummary.summary?.map((item, index) => (
                <li itemType="" className="text-base list-item text-[#303031cc] mb-1" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
