import React from "react";

const ScheduleList = ({ dateAndTime, size = "base" }) => {
  const formatDays = (days) => {
    if (days.length === 0) return "";
    // Join all but the last day with commas
    const initialDays = days.slice(0, -1).join(", ");
    // Add 'and' before the last day if there are multiple days
    const lastDay = days.length > 1 ? `and ${days[days.length - 1]}` : days[days.length - 1];
    return `${initialDays} ${lastDay}`;
  };

  return (
    <ul className="mx-auto px-5 ml-2 text-xs list-disc list-inside">
      {dateAndTime.map((item, index) => (
        <li key={index} className={`text-gray-700 text-${size}`}>
          Every {formatDays(item.days)} from {new Date(item.startTime).toLocaleTimeString()} to {new Date(item.endTime).toLocaleTimeString()}. {item.isReccuring && "Repeats every week."}
        </li>
      ))}
    </ul>
  );
};

export default ScheduleList;
