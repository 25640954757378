import React from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const GenerateReport = () => {
  const navigate = useNavigate();

  return (
    <>
      <SearchBar />
      <div className="flex flex-wrap items-center gap-3 px-6 py-4">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard </span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>
        <span className="cursor-pointer text-[16px] text-[#696976]" onClick={() => navigate("/report")}>
          Report/Audit
        </span>
        <span className="text-[16px] text-[#696976]">/ </span>
        <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Generate New Report</span>
      </div>

      <h1 className="px-6 py-2 text-[#161D20] font-bold text-[24px]">Generate New Report</h1>

      <div className="px-6 py-2 ">
        <div className="p-6 border rounded-lg shadow-md md:w-2/4 ">
          <div className="flex flex-col gap-4 md:flex-row md:gap-6">
            <div className="flex flex-col gap-2 ">
              <label className="text-[#444454]  text-[18px]">Client Name</label>
              <Select className="h-14  w-full   text-[#444454] cursor-pointer rounded-[8px] outline-none " placeholder="Selected Client">
                <Option value="">Jatin</Option>
                <Option value="">Mohit</Option>
                <Option value="">Harsh</Option>
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-[#444454]  text-[18px]">From Date</label>
              <input type="date" placeholder="From Date" className="p-4 border w-full text-[#444454] cursor-pointer rounded-[8px] outline-none " />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-[#444454]  text-[18px]">To Date</label>
              <input type="date" placeholder="To date" className="p-4 border  w-full text-[#444454] cursor-pointer rounded-[8px] outline-none " />
            </div>
          </div>
          <div className="mt-4 ">
            <span className="text-[#444454]  text-[18px]">Send Report to</span>
            <input type="text" className="p-4 border mt-2  w-full text-[#444454] cursor-pointer rounded-[8px] outline-none " />
          </div>
        </div>

        <button className="bg-[#3795F7] px-6 py-3 text-white  font-[500] rounded-[8px] mt-6">Submit Report</button>
      </div>
    </>
  );
};

export default GenerateReport;
