import React, { useState, useCallback } from "react";
import { Modal, Slider, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Cropper from "react-easy-crop";
import axios from "axios";
import heic2any from "heic2any";
import getCroppedImg from "../utils/cropImage"; // Utility function to get the cropped image
import authAxios from "../redux/actions/AxiosInstance/authenticated";

const ImageUploader = ({ imageKeyFieldId, modelId, modelType, initialImageUrl, onImageUpload, autoUpload }) => {
  const [uploading, setUploading] = useState(false);
  const [uploadPer, setUploadPer] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(initialImageUrl || "");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const fetchUploadUrl = async (file) => {
    const fileName = "client-image.jpeg";
    const { data } = await authAxios.post("/assets/create-profile-img", {
      fileName,
      fileType: file.type,
      id: modelId,
      model: modelType,
    });
    return data;
  };

  const handleUploadImage = async (e) => {
    setSelectedFile(null);
    const file = e.target.files[0];
    if (file && file.name.includes("heic")) {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        const convertedFile = new File([convertedBlob], file.name.replace(/\.heic$/i, ".jpg"), { type: "image/jpeg" });
        setSelectedFile(convertedFile);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedFile(file);
    }
    setModalVisible(true);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(URL.createObjectURL(selectedFile), croppedAreaPixels);
      setCroppedImage(croppedImage);
      setImagePreviewUrl(URL.createObjectURL(croppedImage));
      setModalVisible(false);
      if (autoUpload) {
        await uploadImage(croppedImage);
      }
      // await uploadImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const uploadImage = async (file) => {
    setUploading(true);
    const data = await fetchUploadUrl(file);
    await axios.put(data.presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        setUploadPer(percentage);
      },
    });
    document.getElementById(imageKeyFieldId).value = data.key;
    onImageUpload(data.key);
    setUploading(false);
  };

  return (
    <>
      <div className="md:p-0 my-4 w-full ">
        <button className="hidden" id="triggerUploadManual">
          Trigger Upload
        </button>

        <label htmlFor="imgInput" className="cursor-pointer hover:shadow-md p-4 rounded-[8px] w-full outline-dashed outline-gray-200 md:w-fit mx-auto flex flex-col items-center justify-center gap-2">
          {uploading ? (
            <Progress type="circle" percent={uploadPer} />
          ) : imagePreviewUrl ? (
            <div className="relative text-center">
              <img src={imagePreviewUrl} alt="Preview" style={{ width: 300, position: "relative" }} />
              <span className="text-gray-400 text-sm p-2">Click to update profile picture</span>
            </div>
          ) : (
            <>
              <UploadOutlined style={{ fontSize: "24px" }} />
              <span>Click to Upload profile picture</span>
            </>
          )}
          <input type="file" accept="image/*" id="imgInput" className="hidden" onChange={handleUploadImage} />
          <input type="text" required={false} id={imageKeyFieldId} name={imageKeyFieldId} className="hidden" />
        </label>
      </div>

      <Modal visible={modalVisible} onOk={showCroppedImage} onCancel={() => setModalVisible(false)} className="">
        <div className="crop-container h-64 w-80">
          <Cropper
            image={selectedFile ? URL.createObjectURL(selectedFile) : ""}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            style={{
              containerStyle: {
                width: "100%",
                height: "74%",
                border: "5px",
                borderRadius: "0.5rem",
              },
            }}
          />
        </div>
        <div className="mt-24">
          <Slider value={zoom} min={1} max={3} step={0.1} onChange={(value) => setZoom(value)} style={{ marginTop: 100 }} />
        </div>
      </Modal>
    </>
  );
};

export default ImageUploader;
