import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import { BsThreeDotsVertical } from "react-icons/bs";
import { UploadOutlined } from "@ant-design/icons";
import { Modal, Select, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import authAxios from "../../redux/actions/AxiosInstance/authenticated";
const Dashboard = () => {
  const [visibleCards, setVisibleCards] = useState(12);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const { employees } = useSelector((state) => state.employees);
  const { clients } = useSelector((state) => state.clients);
  const user = useSelector((state) => state.auth.user);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPer, setUploadPer] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(selectedClient?.imageUrl || "");
  const [selectedFile, setSelectedFile] = useState(null);
  const fetchUploadUrl = async (file, sessionId, email) => {
    const fileName = file.name;
    // Step 1: Get the presigned URL for uploading
    const { data } = await authAxios.post("/assets/create-session-recording", {
      fileName: file.name,
      fileType: file.type,
      sessionId: sessionId,
      email: email,
      requestType: "upload",
    });
    return data;
  };

  const handleUploadAudio = (e) => {
    setUploading(false);
    setUploadPer(0);
    const file = e.target.files[0];

    setSelectedFile(file); // Store the selected file in state
    setImagePreviewUrl(URL.createObjectURL(file)); // Preview the image
  };

  const uploadImage = async (file, sessionId, email) => {
    setUploading(true);
    const uploadUrlData = await fetchUploadUrl(file);
    await axios.put(uploadUrlData.presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        setUploadPer(percentage);
      },
    });
    const { data } = await authAxios.post("/assets/create-session-recording", {
      fileName: file.name,
      assetId: uploadUrlData.asset.id,
      fileType: file.type,
      sessionId: sessionId,
      email: email,
      requestType: "view",
    });
    setUploading(false);
    setImagePreviewUrl(null);
    setSelectedEmployee(null);
    setUploadPer(0);
    setSelectedFile(null);
    setSelectedClient(null);
    setModalOpen(false);
  };
  // const handleSeeMore = () => {
  //   // Increase the number of visible cards by, for example, 12
  //   setVisibleCards((prevVisibleCards) => prevVisibleCards + 12);
  // };
  const saveSession = async () => {
    console.log(selectedClient);
    console.log(selectedEmployee);
    // return;
    // make api call to save session
    const { data } = await authAxios.post("/sessions/create", {
      clientId: selectedClient,
      employeeId: selectedEmployee,
    });
    let email = clients.find((client) => client.id === selectedClient).email;
    if (selectedFile) {
      uploadImage(selectedFile, data.session.id, email);
    }
  };
  return (
    <>
      <SearchBar />
      <Modal title="Save a Session" width={"40rem"} okText={"Save"} centered open={modalOpen} onOk={saveSession} onCancel={() => setModalOpen(false)}>
        <div className="w-full  grid grid-cols-2 justify-center items-center mb-8">
          <div className="flex flex-col justify-start items-center gap-2">
            <div>
              <h2 className="my-1 ml-1">Select Employee:</h2>
              <Select
                showSearch
                placeholder="Select an employee"
                value={selectedEmployee}
                optionFilterProp="children"
                filterOption={(input, option) => option.props.label.props.children[0].toLowerCase().includes(input.toLowerCase())}
                className="w-60"
                onChange={(e) => setSelectedEmployee(e)}
                options={employees.map((employee) => ({
                  value: employee.id,
                  label: (
                    <>
                      {employee.fullName} <span className="text-gray-500 text-xs">{employee.email}</span>
                    </>
                  ),
                }))}
              />
            </div>
            <div>
              <h2 className="my-1 ml-1 mt-4">Select Client:</h2>
              <Select
                showSearch
                placeholder="Select a Client"
                value={selectedClient}
                optionFilterProp="children"
                filterOption={(input, option) => option.props.label.props.children[0].toLowerCase().includes(input.toLowerCase())}
                className="w-60"
                onChange={(e) => setSelectedClient(e)}
                options={clients.map((employee) => ({
                  value: employee.id,
                  label: (
                    <>
                      {employee.fullName} <span className="text-gray-500 text-xs">{employee.email}</span>
                    </>
                  ),
                }))}
              />
            </div>
          </div>
          <div
            className="mx-auto w-full px-auto justify-center text-center"
            onClick={() => {
              document.getElementById("imgInput").click();
            }}>
            {uploading ? (
              <Progress type="circle" percent={uploadPer} />
            ) : imagePreviewUrl ? (
              <div className="flex flex-col text-center">
                <audio controls className="w-full p-2  ">
                  <source src={imagePreviewUrl} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
                <span className="text-gray-400 text-sm p-2">Click to change audio</span>
              </div>
            ) : (
              <div className="flex flex-col justify-center w-1/2 mx-auto bg-slate-100 p-6 rounded-md cursor-pointer">
                <div className="w-fit mx-auto">
                  <UploadOutlined style={{ fontSize: "44px" }} />
                </div>
                <span className="text-center">Click to upload audio</span>
              </div>
            )}
            <input type="file" id="imgInput" name="image" className="hidden" onChange={handleUploadAudio} />
          </div>
        </div>
      </Modal>
      <div className="flex items-center justify-between  mt-4">
        <div className="flex flex-wrap gap-4 px-5 py-4 cursor-pointer">
          <div className="  text-sm text-white p-1 md:px-5 md:py-2 flex items-center font-[500] justify-center rounded-[4px] bg-[#3795F7]">All session</div>
          <div className="  text-sm text-white p-1 md:px-5 md:py-2 flex items-center font-[500] justify-center rounded-[4px] bg-[#3795F7]">Ongoing</div>
          <div className="  text-sm text-white p-1 md:px-5 md:py-2 flex items-center font-[500] justify-center rounded-[4px] bg-[#3795F7]">Recent</div>
          <div className="  text-sm text-white p-1 md:px-5 md:py-2 flex items-center font-[500] justify-center rounded-[4px] bg-[#3795F7]">Future</div>
        </div>
        {user?.role == "admin" && (
          <button className="mr-8 bg-slate-200 p-2 px-4 rounded-lg hover:shadow-lg" onClick={() => setModalOpen(true)}>
            Add a new Session
          </button>
        )}
      </div>

      <div className="pr-4 ml-5 ">
        <div className="grid grid-cols-1 gap-4 cursor-pointer sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
          {/* {yourCardData.slice(0, visibleCards).map((card, index) => ( */}
          <div className="p-4 border shadow-[12px] rounded-[8px] ">
            <div className="flex items-center justify-between ">
              <span className="font-semibold text-[16px]">Client Name</span>
              <span className="text-[15px] text-[#444454CC]">Akash Chopra</span>
              <BsThreeDotsVertical className="text-[#444454] cursor-pointer" />
            </div>
            <div className="flex items-center gap-9">
              <span className="text-[#444454] text-[15px]">Employee Name</span>
              <span className="text-[14px] text-[#444454CC]">Sajal Khan</span>
            </div>
            <div className="flex items-center gap-28">
              <span className="text-[#444454] text-[15px] ">Time</span>
              <span className="text-[14px]  text-[#444454CC]">10:00 PM</span>
            </div>
            <div className="flex items-center gap-28">
              <span className="text-[#444454] text-[15px]">Date</span>
              <span className="text-[14px] text-[#444454CC]">12-01-24</span>
            </div>
            {/* Audio Player */}
            <div className="flex ">
              <audio controls className="p-1 mt-6 w-[17rem]">
                <source src="your-audio-file.mp3" type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>

      <div className="flex items-center justify-between gap-4 px-6 mt-4">
        <span className=" w-full h-1  rounded-sm bg-[#D0D5DD]"></span>
        <div
          className="p-2 w-2/3 cursor-pointer bg-[#F9FAFB] rounded-[32px] flex items-center justify-center"
          // onClick={handleSeeMore}
        >
          <span className="text-[#444454] font-semibold">See more</span>
        </div>
        <span className=" w-full h-1  rounded-sm bg-[#D0D5DD]"></span>
      </div>
    </>
  );
};

export default Dashboard;
