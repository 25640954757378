import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/web`,
  // baseURL:  'https://app.getdex.ai/api'
});

//request interceptor to add the auth token header to requests
instance.interceptors.request.use(
  (config) => {
    // console.log(`${process.env.REACT_APP_API_URL}/web`);
    const accessToken = localStorage.getItem("accessToken");
    // console.log(accessToken);
    const accessHeader = `Bearer ${accessToken}`;
    if (accessToken) {
      config.headers["Authorization"] = accessHeader;
      return config;
    } else {
      return Promise.reject("No token found");
    }
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
