// src/pages/SIRs/View.js

import React from "react";
import { CiEdit } from "react-icons/ci";
import ViewPdf from "../../components/ViewPdf";

const View = ({ currentMisc, setCurrentMisc, selectedClient, allMisc, setAllMisc, setMode, setSelectedIndexes }) => {
  return (
    <div className="col-span-6 p-4 rounded-[8px] border shadow-md h-[80vh] overflow-y-scroll">
      <div className="flex items-center justify-end px-2 pb-2">
        <p className="text-[#444454] text-sm"></p>
      </div>
      <div className="rounded-[8px] mb-16 h-screen">
        <div className="flex justify-between">
          <h2 className="pb-2 text-sm text-gray-500">
            <span className="font-bold">{currentMisc?.docTitle}</span>
          </h2>
          <h2 className="pb-2 text-sm text-gray-500 flex">
            <span>ID: {currentMisc?.id}</span>
            {/* <CiEdit className="cursor-pointer text-lg ml-2" onClick={() => setMode("edit")} /> */}
          </h2>
        </div>
        {currentMisc?.url && currentMisc?.filetype?.includes("pdf") && <ViewPdf fileUrl={currentMisc.url} />}
      </div>
    </div>
  );
};

export default View;
