import React from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

const SessionDetails = () => {
  return (
    <>
      <SearchBar />
      <div className="flex items-center gap-3 px-6 py-4 ">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] font-[400] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard </span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">Session Details</span>
      </div>

      <h1 className="px-6 py-2 text-[#161D20] font-[600] text-[24px]">Session Details</h1>

      {/* left side */}
      <div className="grid grid-cols-1 gap-4 px-6 py-2 md:grid-cols-2 ">
        <div className="p-2 rounded-[8px] w-full border shadow-md">
          <div className="bg-[#D9D9D9] flex items-center justify-center rounded-[8px] px-1 relative h-[324px]">
            <audio controls className="absolute w-full p-2 bottom-2 ">
              <source src="your-audio-file.mp3" type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div className="py-2">chats</div>
        </div>

        {/* right side */}
        <div className="p-4 rounded-[8px] border shadow-md ">
          <div className="relative w-full cursor-pointer">
            <div className="absolute inset-y-0 left-0 flex items-center ml-2 cursor-pointer">
              <FiSearch className="text-[#667085] text-[18px] " />
            </div>
            <input type="text" className="w-full py-2 pl-8 text-[18px] text-[#667085] rounded-[8px] border outline-none" placeholder="Search " />
          </div>

          {/* employee details */}
          <div className="w-full p-2 mt-3 ">
            {/* name */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">Harshdeep Yadav</h1>
              <BsThreeDotsVertical className="text-[#444454] cursor-pointer" />
            </div>
            {/* time & date */}
            <div className="flex flex-row gap-4 mt-3">
              <span className="text-[16px]  text-[#444454CC]">10 : 52 pm</span>
              <span className="text-[16px]  text-[#444454CC]">10/12/2023</span>
            </div>
            {/* role */}
            <p className="text-[16px] mt-3  text-[#444454CC]">Employee</p>
          </div>
          {/* Client details */}
          <div className="w-full p-2 mt-3 ">
            {/* name */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">Mohit Yadav</h1>
              <BsThreeDotsVertical className="text-[#444454] cursor-pointer" />
            </div>
            {/* time & date */}
            <div className="flex flex-row gap-4 mt-3">
              <span className="text-[16px]  text-[#444454CC]">10 : 52 pm</span>
              <span className="text-[16px]  text-[#444454CC]">10/12/2023</span>
            </div>
            {/* role */}
            <p className="text-[16px] mt-3  text-[#444454CC]">Client</p>
          </div>

          {/* Audio Sharing */}
          <div className="w-full p-2 mt-3 ">
            {/* name */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">Audio Sharing</h1>
              <BsThreeDotsVertical className="text-[#444454] cursor-pointer" />
            </div>
            {/* time & date */}
            <div className="flex flex-row gap-4 mt-3">
              <span className="text-[16px]  text-[#444454CC]">10 : 52 pm</span>
              <span className="text-[16px]  text-[#444454CC]">10/12/2023</span>
            </div>
            {/* time  */}
            <div className="flex flex-col gap-4 mt-3">
              <div className="flex flex-row gap-10">
                <span className="text-[16px]  text-[#444454CC]">Time</span>
                <span className="text-[16px]  text-[#444454CC]">10 : 52 pm</span>
              </div>
              <div className="flex flex-row gap-10">
                <span className="text-[16px]  text-[#444454CC]">Date</span>
                <span className="text-[16px]  text-[#444454CC]">10/12/2023</span>
              </div>
            </div>
          </div>

          {/* Your Question  */}
          <div className="w-full p-2 mt-3 ">
            {/* heading */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">Your Question</h1>
              <BsThreeDotsVertical className="text-[#444454] cursor-pointer" />
            </div>
            <div className="flex flex-col w-full gap-1 px-1">
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
            </div>
          </div>
          {/* There Question  */}
          <div className="w-full p-2 mt-3 ">
            {/* heading */}
            <div className="flex items-center justify-between">
              <h1 className="text-[24px] font-bold text-[#161D20]">There Question</h1>
              <BsThreeDotsVertical className="text-[#444454] cursor-pointer " />
            </div>
            <div className="flex flex-col w-full gap-1 px-1">
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
              <span className="text-[16px]  text-[#444454CC]">Can you share it with me ?</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionDetails;
