import React, { useState, useEffect } from "react";
import { DatePicker, Input, Button, Select, message } from "antd";
import moment from "moment";
import { CiTrash } from "react-icons/ci";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { uploadSirDoc } from "../../redux/actions/clientsAction";
import axios from "axios";
const AddSIRs = ({ currentSIR, setCurrentSIR, selectedClient, allSIRs, setAllSIRs, setMode, setSelectedIndexes }) => {
  const [SIRData, setSIRData] = useState({
    docTitle: "",
    docDesc: "",
    doc: null,
  });
  const [file, setFile] = useState(null);
  const { clients } = useSelector((state) => state.clients);
  const { employees } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const [sirUploading, setSirUploading] = useState(false);
  const [sirUploaded, setSirUploaded] = useState(false);
  const handleChange = (e) => {
    // console.log(e)
    const { name, value } = e.target;
    setSIRData({ ...SIRData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    message.loading("Processing...");
    // update-SIR
    setSirUploading(true);
    setSirUploaded(0);
    // get file from formm
    const tmpSIR = await dispatch(
      await uploadSirDoc({
        fileName: file.name,
        fileType: file.type,
        docTitle: SIRData.docTitle,
        docDesc: SIRData.docDesc,
        clientId: selectedClient.id,
        requestType: "upload",
        email: selectedClient.email,
      })
    );

    if (tmpSIR) {
      const presignedUrl = tmpSIR.presignedUrl;
      // Upload to S3 with axios
      const { status } = await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          setSirUploaded(uploadPercentage);
        },
      });
      if (status === 200) {
        const SIR = await dispatch(
          await uploadSirDoc({
            sirId: tmpSIR.sir.id,
            requestType: "view",
          })
        );
        setSirUploading(false);
        setSirUploaded(100);
        // setAllSIRs([tmpSIR, ...allSIRs]);
        setSIRData({ docTitle: "", docDesc: "", doc: null });
        setSelectedIndexes({
          SIR: 0,
        });
        setCurrentSIR(SIR);
        setAllSIRs([SIR, ...allSIRs]);
        setMode("view");
      }
    }
  };
  const years = Array.from({ length: 2035 - 2000 + 1 }, (v, k) => k + 2000);

  return (
    <div className="col-span-6 p-4 rounded-[8px] border shadow-md h-[80vh] overflow-y-scroll">
      <div className="w-full p-2 mt-3 ">
        <div className="flex items-center justify-between">
          <h1 className="text-[24px] font-bold text-[#161D20]">{selectedClient.fullName}</h1>
        </div>
        <div className="flex flex-row gap-4 mt-0">
          <span className="text-[16px]  text-[#444454CC]">{selectedClient.uci}</span>
          {selectedClient?.email && <p className="text-[#444454] text-[1rem]">{selectedClient?.email && selectedClient.email}</p>}
        </div>
        {/* role */}
      </div>
      <form onSubmit={handleSave}>
        <div className="w-full flex gap-4 items-center mt-8">
          <label htmlFor="docTitle" className="text-[#444454] text-[14px] w-1/4">
            Document Title
            <br />
            <span className="text-xs">(Optional)</span>
          </label>
          <Input name="docTitle" value={SIRData.docTitle} onChange={handleChange} placeholder="Document Title" className="" required={false} />
        </div>
        <div className="w-full flex mt-4 gap-4 items-center">
          <label htmlFor="docDesc" className="text-[#444454] text-[14px] w-1/4">
            Document Description
            <br />
            <span className="text-xs">(Optional)</span>
          </label>
          <Input.TextArea name="docDesc" value={SIRData.docDesc} onChange={handleChange} placeholder="Document Description" className="" required={false} />
        </div>
        <div className="w-full flex mt-4 gap-4 items-center">
          <label htmlFor="SIR" className="text-[#444454] text-[14px] w-1/4">
            Document
          </label>
          <input type="file" name="SIR" onChange={(e) => setFile(e.target.files[0])} className="ml-0" required />
        </div>
        <div className="flex gap-4 items-center mt-6">
          <button type="button" className="text-sm" onClick={() => setMode("view")}>
            Cancel
          </button>
          <Button type="primary" htmlType="submit" className="mt-3 mb-5 disabled:bg-primary disabled:text-white" disabled={sirUploading}>
            {!sirUploading ? "Save Changes" : sirUploaded}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddSIRs;
