import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import axios from "axios";
import { message } from "antd";

export const handleSignIn = async ({ email, password }) => {
  try {
    const { data, status } = await instance.post(
      `/user/signin`,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      localStorage.setItem("role", data.user.role);
      return data;
    }
  } catch (error) {
    console.log(error, "Error in signin");
    let errorMessage = "";
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Unexpected Error!";
    }
    message.error(errorMessage);
    return false;
  }
};

export const handleSignUp = async ({ email, password, name }) => {
  try {
    const { data, status } = await instance.post(
      `/user/signup`,
      {
        email: email,
        password: password,
        name: name,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    return false;
  }
};

export const getUserInfo = () => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get("/user/get-user-info");
    if (status === 200) {
      dispatch({
        type: "SET_USER",
        payload: data.user,
      });
      localStorage.setItem("role", data.user.role);
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    return false;
  }
};

export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("planStatus");
    localStorage.removeItem("role");
    localStorage.removeItem("loglevel");

    dispatch({
      type: "LOGOUT",
      payload: "logout",
    });
    return true;
  } catch (error) {
    console.log(error, "Error in signup");

    return false;
  }
};
