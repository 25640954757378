import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal } from "antd";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarDates = ({ events }) => {
  // console.log(events);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleSelectSlot = ({ start, end }) => {
    console.log("Selected slot:", start, end);
    // Handle selected slot logic
  };

  const handleSelectEvent = (event) => {
    console.log("Selected event:", event);
    setSelectedEvent(event);
  };

  const EventComponent = (prop) => {
    return (
      <div className="text-[0.65rem] rounded-lg">
        <div className="text-[0.75rem] font-medium">{prop.event.title}</div>
        <p>{prop.event.description}</p>
      </div>
    );
  };

  return (
    <div className="mx-auto">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800, width: "100%" }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        components={{ event: EventComponent }}
        eventPropGetter={(event) => {
          const eventStyle = () => {
            switch (event.type) {
              case "session":
                return { backgroundColor: "#007bff", display: "block" };
              case "mar":
                return { backgroundColor: "#81c784", display: "block" };
              default:
                return { backgroundColor: "#a0aec0", display: "block" };
            }
          };
          return {
            style: eventStyle(),
          };
        }}
      />

      <Modal visible={selectedEvent !== null} onCancel={() => setSelectedEvent(null)} footer={null} className="modal">
        {selectedEvent && (
          <div className="p-6 bg-white modal-content">
            <h2 className="mb-4 text-2xl font-bold">{selectedEvent.title}</h2>
            <p>{`Start: ${selectedEvent.start.toLocaleString()}`}</p>
            <p>{`End: ${selectedEvent.end.toLocaleString()}`}</p>
            {/* Add more details or custom modal content here */}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CalendarDates;
