import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { FiEye, FiEyeOff } from "react-icons/fi";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { message } from "antd";
import { useSelector } from "react-redux";

const Settings = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    try {
      const { status } = await authenticatedInstance.post("/user/create-login", data);
      if (status === 200) {
        message.success("User Created Successfully");
        form.reset();
      } else {
        message.error("Failed to create user");
      }
      fetchAllUsers();
    } catch (error) {
      console.log(error);
      let errorMessage = error.response?.data?.message || "Unexpected Error!";
      message.error(errorMessage);
    }
  };

  const updateLoginAccess = async (e, id) => {
    e.preventDefault();

    try {
      const { data, status } = await authenticatedInstance.post("/user/update-login-access", {
        id,
        loginEnabled: e.target.checked,
      });
      if (status === 200) {
        message.success("User Created Successfully");
        setAllUsers(data.users);
      }
    } catch (error) {
      console.log(error);
      let errorMessage = error.response?.data?.message || "Unexpected Error!";
      message.error(errorMessage);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const { data, status } = await authenticatedInstance.get("/user/get-all-logins");
      if (status === 200) {
        setAllUsers(data.users);
      }
    } catch (error) {
      console.log(error);
      let errorMessage = error.response?.data?.message || "Unexpected Error!";
      message.error(errorMessage);
    }
  };

  const handleupdatePassword = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    try {
      const { status } = await authenticatedInstance.post("/user/update-password", data);
      if (status === 200) {
        message.success("Success!");
        form.reset();
      } else {
        message.error("Failed to create user");
      }
    } catch (error) {
      console.log(error);
      let errorMessage = error.response?.data?.message || "Unexpected Error!";
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <>
      <SearchBar />
      <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4 px-4 py-2">
        <div className="p-5 rounded-[8px] col-span-1 h-fit w-full border shadow-md">
          <form onSubmit={handleupdatePassword}>
            <h1 className="text-[#161D20] font-[600] text-[24px]">Update Users Password</h1>
            <div className="grid grid-cols-1 gap-3 mt-4">
              <div className="flex flex-col gap-1">
                <label htmlFor="id" className="text-[#444454] text-[16px]">
                  Select a User
                </label>
                <select name="id" id="id" className="text-sm w-full py-2 pl-4 text-[16px] text-[#444454] rounded-[8px] border outline-none">
                  {allUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.firstName} {user.lastName} {user.email}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="password" className="text-[#444454] text-[16px]">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="w-full py-2 pl-4 text-[16px] text-[#444454] rounded-[8px] border outline-none text-sm"
                  placeholder="Password"
                />
                <div className="absolute right-3 top-[2.5rem] cursor-pointer" onClick={togglePasswordVisibility}>
                  {showPassword ? <FiEye size={15} /> : <FiEyeOff size={15} />}
                </div>
              </div>
            </div>
            <div className="flex justify-start mt-2">
              <button type="submit" className="btn-primary mt-2">
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="p-5 rounded-[8px] col-span-2 w-full border shadow-md">
          <form onSubmit={handleSubmit}>
            <h1 className="text-[#161D20] font-[600] text-[24px]">Create New Login</h1>
            <div className="grid grid-cols-2 gap-3 mt-4">
              <div className="flex flex-col gap-1">
                <label htmlFor="name" className="text-[#444454] text-[16px]">
                  Name
                </label>
                <input type="text" name="name" className="w-full py-2 pl-3 text-[16px] text-[#444454] rounded-[8px] border outline-none text-sm" placeholder="Name" />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="email" className="text-[#444454] text-[16px]">
                  Email
                </label>
                <input type="email" name="email" className="w-full py-2 pl-3 text-[16px] text-[#444454] rounded-[8px] border outline-none text-sm" placeholder="Email" />
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="password" className="text-[#444454] text-[16px]">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="w-full py-2 pl-4 text-[16px] text-[#444454] rounded-[8px] border outline-none text-sm"
                  placeholder="Password"
                />
                <div className="absolute right-3 top-[2.5rem] cursor-pointer" onClick={togglePasswordVisibility}>
                  {showPassword ? <FiEye size={15} /> : <FiEyeOff size={15} />}
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="role" className="text-[#444454] text-[16px]">
                  Role
                </label>
                <select name="role" id="role" className="text-sm w-full py-2 pl-4 text-[16px] text-[#444454] rounded-[8px] border outline-none">
                  <option value="admin">Admin</option>
                  <option value="auditor">Auditor</option>
                </select>
              </div>
            </div>
            <div className="flex justify-start mt-2">
              <button type="submit" className="btn-primary mt-2">
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="p-6 rounded-[8px] col-span-3 w-full border shadow-md">
          <h1 className="text-[#161D20] font-[600] text-[24px]">Users with Login access</h1>
          <table className="w-full mt-4">
            <thead>
              <tr className="text-center text-sm">
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-center text-sm">
              {allUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    {new Date(user.createdAt).toDateString()} {new Date(user.createdAt).toLocaleTimeString()}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="active"
                      id="active"
                      checked={user.loginEnabled}
                      onChange={(e) => {
                        updateLoginAccess(e, user.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Settings;
